import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useShopContext } from "../../context/ShopContextProvider";


export const ShopLandingScreen = () => {
    const theme = useTheme()
    const shopCtx = useShopContext()
    const nav = useNavigate()

    return (
        <Box sx={{ p: 2, width: { lg: theme.breakpoints.values.lg }, margin: 'auto' }}>
            <Typography color='#757575'>ดำเนินงาน</Typography>
            <Stack direction='row' gap={2} useFlexGap flexWrap="wrap" my={2}>
                {shopCtx.menuData?.map((menu) => {
                    return (
                        <Box sx={{
                            borderRadius: 1,
                            backgroundColor: menu.key !== '/:shopId/coming-soon' ? 'white' : '#eeeeee',
                            boxShadow: 1,
                            p: 4,
                            width: { md: '260px', xs: '100%' },
                            height: '150px',
                            cursor: menu.key !== '/:shopId/coming-soon' ? 'pointer' : 'default'
                        }}
                            onClick={() => {
                                if (menu.key !== '/:shopId/coming-soon')
                                    nav(menu.key.replace(':shopId', shopCtx.shopId!))
                            }}>
                            <Typography variant='h5'>{menu.label}</Typography>
                        </Box>
                    )
                })
                }
            </Stack>
            <Divider sx={{ my: 4 }} />
            <Typography color='#757575'>ตั้งค่า</Typography>
            <Stack direction='row' gap={2} useFlexGap flexWrap="wrap" my={2}>
                {shopCtx.shopMenuData?.map((menu) => {
                    return (
                        <Box sx={{
                            borderRadius: 1,
                            backgroundColor: 'white',
                            boxShadow: 1,
                            p: 4,
                            width: { md: '260px', xs: '100%' },
                            height: '150px',
                            cursor: 'pointer'
                        }}
                            onClick={() => nav(menu.key.replace(':shopId', shopCtx.shopId!))}>
                            <Typography variant='h5'>{menu.label}</Typography>
                        </Box>
                    )
                })
                }
            </Stack>
        </Box>
    )
}