/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ShopUserResponse = {
    firstName?: string;
    lastName?: string;
    email?: string;
    role?: ShopUserResponse.role;
};
export namespace ShopUserResponse {
    export enum role {
        OWNER = 'OWNER',
        STAFF = 'STAFF',
    }
}

