/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Profile } from '../models/Profile';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ProfileService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns Profile OK
     * @throws ApiError
     */
    public getLatestProfileByIdentifier({
        identifier,
    }: {
        identifier: string,
    }): CancelablePromise<Profile> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/profiles/{identifier}',
            path: {
                'identifier': identifier,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
