/* eslint-disable */
import { Box, Card, FormControl, MenuItem, Select, Stack, TextField, Typography } from "@mui/material"
import { MaterialReactTable, MRT_ColumnDef, useMaterialReactTable } from "material-react-table"
import { useEffect, useMemo, useState } from "react"
import CustomInputLabel from "../../../component/CustomInputLabel"

interface LoanCalculationResult {
    row: number,
    monthCount: number,
    downPercentage: number,
    downPaymentAmount: number,
    loanCostAmount: number,
    installmentAmount: number,
    profitFactor: number,
    profit: number,
    totalAmount: number,
}

interface LoanCalculationInput {
    row: number,
    downPercentage: number,
    month: number,
    profitFactor: number,
}

export const LoanCalculatorScreen = () => {
    const [cost, setCost] = useState<number>(0)
    const [isRounding, setIsRounding] = useState<boolean>(true)
    const [calculatedData, setCalculatedData] = useState<LoanCalculationResult[]>([])
    const [monthProfitFactors, setMonthProfitFactors] = useState<LoanCalculationInput[]>(() => {
        const storedData = localStorage.getItem('monthProfitFactors');
        return storedData
            ? JSON.parse(storedData)
            : [
                { row: 1, downPercentage: 50, month: 3, profitFactor: 1.8 },
                { row: 2, downPercentage: 50, month: 4, profitFactor: 1.9 },
                { row: 3, downPercentage: 50, month: 6, profitFactor: 2 },
                { row: 4, downPercentage: 50, month: 8, profitFactor: 2.2 },
                { row: 5, downPercentage: 50, month: 10, profitFactor: 2.4 },
                { row: 6, downPercentage: 50, month: 12, profitFactor: 2.6 },
                { row: 7, downPercentage: 50, month: 14, profitFactor: 2.8 },
                { row: 8, downPercentage: 50, month: 16, profitFactor: 3 },
            ];
    });

    const currencyFormat = Intl.NumberFormat('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    useEffect(() => {
        localStorage.setItem('monthProfitFactors', JSON.stringify(monthProfitFactors));
        const result: LoanCalculationResult[] = monthProfitFactors.map((monthProfitFactor) => {
            const downPercentage = monthProfitFactor.downPercentage
            const month = monthProfitFactor.month
            const profitFactor = monthProfitFactor.profitFactor
            const downPaymentAmount = isRounding ? Math.ceil(Math.round(downPercentage / 100 * cost)) - 1 : Math.round(downPercentage / 100 * cost)
            const loanCostAmount = cost - downPaymentAmount
            const installmentAmount = isRounding ? Math.ceil((Math.round((profitFactor * loanCostAmount) / month) / 100)) * 100 - 1 : Math.round((profitFactor * loanCostAmount) / month / 10) * 10
            const totalAmount = downPaymentAmount + (installmentAmount * month)
            return {
                row: monthProfitFactor.row,
                monthCount: month,
                downPercentage: downPercentage,
                downPaymentAmount: downPaymentAmount,
                loanCostAmount: loanCostAmount,
                profitFactor: profitFactor,
                installmentAmount: installmentAmount,
                totalAmount: downPaymentAmount + (installmentAmount * month),
                profit: totalAmount - cost,
            };
        })
        setCalculatedData(result)

    }, [cost, isRounding, monthProfitFactors])

    const columns = useMemo<MRT_ColumnDef<LoanCalculationResult>[]>(
        () => [
            {
                id: 'monthCount',
                accessorFn: (row: LoanCalculationResult) => {
                    return <Typography>{`ผ่อน ${row.monthCount} เดือน`}</Typography>
                }, //simple recommended way to define a column
                header: '',
                enableHiding: false,
                enablePinning: false,
                enableSorting: false,
                size: 100,
                Cell: ({ renderedCellValue, row }) => (
                    <Box width={100}>
                        <Typography noWrap >
                            {renderedCellValue}
                        </Typography>
                    </Box>
                ),
            },
            {
                id: 'downPercentage',
                accessorFn: (row: LoanCalculationResult) => {
                    return <Stack direction={'row'} alignItems='center' gap={1}>
                        <TextField size="small" value={row.downPercentage} onChange={(e) => {
                            const newFactors = monthProfitFactors.map((monthProfitFactor) => {
                                if (monthProfitFactor.row === row.row) {
                                    monthProfitFactor.downPercentage = e.target.value ? parseFloat(e.target.value) : 0
                                }
                                return monthProfitFactor
                            })
                            setMonthProfitFactors(newFactors)
                        }} /><Typography>{' %'}</Typography>
                    </Stack>
                }, //simple recommended way to define a column
                header: 'เปอร์เซ็นต์วางดาว์น',
                enableHiding: false,
                enablePinning: false,
                enableSorting: false,
                muiTableBodyCellProps: {
                    align: 'right',
                },
                size: 100,
                Cell: ({ renderedCellValue, row }) => (
                    <Box>
                        {renderedCellValue}
                    </Box>
                ),
            },
            {
                id: 'downPaymentAmount',
                accessorFn: (row: LoanCalculationResult) => <Typography >{cost > 0 ? currencyFormat.format(row.downPaymentAmount || 0) : ''}</Typography>,
                header: 'ดาว์น',
                enableHiding: false,
                enablePinning: false,
                muiTableBodyCellProps: {
                    align: 'right',
                },
                grow: false,
                enableSorting: false,
            },
            {
                id: 'loanCostAmount',
                accessorFn: (row: LoanCalculationResult) => <Typography >{cost > 0 ? currencyFormat.format(row.loanCostAmount || 0) : ''}</Typography>,
                header: 'ยอดจัด',
                enableHiding: false,
                enablePinning: false,
                muiTableBodyCellProps: {
                    align: 'right',
                },
                grow: false,
                enableSorting: false,
            },
            {
                id: 'profitFactor',
                accessorFn: (row: LoanCalculationResult) => {
                    return <TextField size="small" type="number" value={row.profitFactor} onChange={(e) => {
                        const newFactors = monthProfitFactors.map((monthProfitFactor) => {
                            if (monthProfitFactor.row === row.row) {
                                monthProfitFactor.profitFactor = e.target.value ? parseFloat(e.target.value) : 0
                            }
                            return monthProfitFactor
                        })
                        setMonthProfitFactors(newFactors)
                    }} />
                },
                header: 'ตัวคูณกำไร',
                enableHiding: false,
                enablePinning: false,
                muiTableBodyCellProps: {
                    align: 'right',
                },
                grow: false,
                enableSorting: false,
            },
            {
                id: 'installmentAmount',
                accessorFn: (row: LoanCalculationResult) => <Typography >{row.installmentAmount > 0 && cost > 0 ? currencyFormat.format(row.installmentAmount) : ''}</Typography>,
                header: 'งวดละ',
                enableHiding: false,
                enablePinning: false,
                muiTableBodyCellProps: {
                    align: 'right',
                },
                grow: false,
                enableSorting: false,
            },
            {
                id: 'totalAmount',
                accessorFn: (row: LoanCalculationResult) => <Typography >{row.totalAmount > 0 && cost > 0 ? currencyFormat.format(row.totalAmount) : ''}</Typography>,
                header: 'ยอดรวม',
                enableHiding: false,
                enablePinning: false,
                muiTableBodyCellProps: {
                    align: 'right',
                },
                grow: false,
                enableSorting: false,
            },
            {
                id: 'profit',
                accessorFn: (row: LoanCalculationResult) => <Typography >{row.profit > 0 && cost > 0 ? currencyFormat.format(row.profit) : ''}</Typography>,
                header: 'กำไร',
                enableHiding: false,
                enablePinning: false,
                muiTableBodyCellProps: {
                    align: 'right',
                },
                grow: false,
                enableSorting: false,
            },
        ],
        [currencyFormat],
    );

    const table = useMaterialReactTable({
        columns: columns,
        data: calculatedData || [],
        layoutMode: 'semantic',
        enablePagination: false,
        enableColumnPinning: true,
        enableGlobalFilter: false,
        enableColumnFilters: false,
        enableSorting: true,
        enableHiding: false,
        enableColumnActions: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableColumnVirtualization: false,
        enableColumnOrdering: false,
        enableBottomToolbar: false,
        positionGlobalFilter: 'left',
        enableTopToolbar: false,
        manualSorting: true,
        paginationDisplayMode: 'pages',
        localization: {
            rowsPerPage: "จำนวนต่อหน้า"
        },
        manualPagination: true,
        initialState: {
            showGlobalFilter: false,
            columnPinning: {
                right: ['mrt-row-actions'],
            },
        },
        enableRowActions: false,
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: '', //change header text
                size: 12, //make actions column wider
            },
        },
        muiTableHeadCellProps: {
            sx: {
                borderRight: '1px solid #e0e0e0', //add a border between columns
                backgroundColor: '#455a64',
                color: 'white',
                fontWeight: '500'
            },
        },
        muiTableBodyProps: {
            sx: {
                //stripe the rows, make odd rows a darker color
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td': {
                    backgroundColor: 'grey.100',
                },
            },
        },
        muiTableBodyCellProps: {
            sx: {
                borderRight: '1px solid #eeeeee', //add a border between columns
            },
        },
    });

    return (
        <Box p={2} mb={16}>
            <Typography variant="h5" p={2}>{
                'เครื่องมือคำนวณสินเชื่อ'
            }</Typography>
            <Card sx={{ px: 2, py: 4, mb: 2 }}>
                <Stack gap={2}>
                    <Stack direction={'row'} gap={2}>
                        <FormControl fullWidth>
                            <CustomInputLabel>ราคาทุนเครื่อง</CustomInputLabel>
                            <TextField size='small' variant="outlined" type="number" onChange={(e) => { setCost(e.target.value ? parseFloat(e.target.value) : 0) }} />
                        </FormControl>
                        <FormControl fullWidth>
                            <CustomInputLabel>ปัดเศษ 99</CustomInputLabel>
                            <Select size='small' variant="outlined" defaultValue={'true'} onChange={(e) => { setIsRounding(e.target.value === "true" ? true : false) }}>
                                <MenuItem value={'true'}>ใช่</MenuItem>
                                <MenuItem value={'false'}>ไม่ใช่</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                    <MaterialReactTable table={table} />
                </Stack>
            </Card>
        </Box>
    )

}