/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InstallmentPaymentDocumentDto } from './InstallmentPaymentDocumentDto';
export type InstallmentPaymentDto = {
    id?: string;
    shopId?: string;
    shopPaymentChannelId?: string;
    customerCitizenId?: string;
    customerPassportNumber?: string;
    transactionAt?: string;
    installmentPlanId?: string;
    installmentTermNumber?: number;
    note?: string;
    appointmentAt?: string;
    appointmentAmount?: number;
    paidFineAmount?: number;
    paidDueAmount: number;
    totalAmount?: number;
    status?: InstallmentPaymentDto.status;
    type?: InstallmentPaymentDto.type;
    documents?: Array<InstallmentPaymentDocumentDto>;
    dateDiffFromDueDate?: number;
};
export namespace InstallmentPaymentDto {
    export enum status {
        SUCCESS = 'SUCCESS',
        CANCEL = 'CANCEL',
        PENDING = 'PENDING',
        COMPLETED = 'COMPLETED',
        OVERDUE = 'OVERDUE',
    }
    export enum type {
        DOWN_PAYMENT = 'DOWN_PAYMENT',
        TERM_PAYMENT = 'TERM_PAYMENT',
        REPAYMENT_PARTIAL = 'REPAYMENT_PARTIAL',
        REPAYMENT_FULL = 'REPAYMENT_FULL',
        FINE = 'FINE',
        APPOINTMENT = 'APPOINTMENT',
        CLOSED = 'CLOSED',
    }
}

