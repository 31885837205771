import { createBrowserRouter } from "react-router-dom";
import { ShopContextProvider } from "../context/ShopContextProvider";
import { BasicLayout } from "../layout/BasicLayout";
import { ShopLayout } from "../layout/ShopLayout";
import { ComingSoonFeatureScreen } from "../screen/ComingSoonFeatureScreen";
import { HomeScreen } from "../screen/HomeScreen";
import { BacklistSearchScreen } from "../screen/shop/BlacklistSearchScreen";
import { InstallmentPlanDetailScreen } from "../screen/shop/installment/InstallmentPlanDetailScreen";
import { InstallmentPlanImportScreen } from '../screen/shop/installment/InstallmentPlanImportScreen';
import { InstallmentPlanListScreen } from "../screen/shop/installment/InstallmentPlanListScreen";
import { LoanApprovalScreen } from "../screen/shop/installment/LoanApprovalScreen";
import { LoanApproveListScreen } from "../screen/shop/installment/LoanApproveListScreen";
import { LoanCalculatorScreen } from "../screen/shop/installment/LoanCalculatorScreen";
import { LoanCreateListScreen } from "../screen/shop/installment/LoanCreateListScreen";
import { LoanCreateScreen } from "../screen/shop/installment/LoanCreateScreen";
import { ShopDetailScreen } from "../screen/shop/settings/ShopDetailScreen";
import { ShopPaymentChannelScreen } from "../screen/shop/settings/ShopPaymentChannelScreen";
import { UserListScreen } from "../screen/shop/settings/UserListScreen";
import { ShopDashboardScreen } from "../screen/shop/ShopDashboardScreen";
import { ShopLandingScreen } from "../screen/shop/ShopLandingScreen";
import { ShopRegisterScreen } from "../screen/ShopRegisterScreen";
import { ShopUnavailableScreen } from "../screen/ShopUnavailableScreen";
import { SignInScreen } from "../screen/SignInScreen";
import { SignUpScreen } from "../screen/SignUpScreen";
import { ProtectedRoute } from "./ProtectedRoute";

export const RouterDefinition = createBrowserRouter(
    [
        {
            path: "/",
            element: <ProtectedRoute element={<BasicLayout />} />,
            children: [
                {
                    path: "",
                    element: <HomeScreen />
                },
                {
                    path: "register-shop",
                    element: <ShopRegisterScreen />
                }
            ]
        },
        {
            path: "sign-in",
            element: <SignInScreen />
        },
        {
            path: "sign-up",
            element: <SignUpScreen />
        },
        {
            path: "/:shopId",
            element: (<ProtectedRoute element={<ShopContextProvider><ShopLayout /></ShopContextProvider>} />),
            children: [
                {
                    index: true,
                    element: <ShopLandingScreen />
                },
                {
                    path: "dashboard",
                    element: <ShopDashboardScreen />
                },
                {
                    path: "settings/shop",
                    element: <ShopDetailScreen />
                },
                {
                    path: "settings/payment-channel",
                    element: <ShopPaymentChannelScreen />
                },
                {
                    path: "loan",
                    element: <InstallmentPlanListScreen />,
                },
                {
                    path: "loan-calculator",
                    element: <LoanCalculatorScreen />,
                },
                {
                    path: "loan/create-list",
                    element: <LoanCreateListScreen />
                },
                {
                    path: "loan/import",
                    element: <InstallmentPlanImportScreen />
                },
                {
                    path: "loan/create",
                    element: <LoanCreateScreen />,
                },
                {
                    path: "loan/approval-list",
                    element: <LoanApproveListScreen />,
                },
                {
                    path: "loan/approval",
                    element: <LoanApprovalScreen />,
                },
                {
                    path: "loan/:installmentPlanId",
                    element: <InstallmentPlanDetailScreen />,
                },
                {
                    path: "blacklist",
                    element: <BacklistSearchScreen />,
                },
                {
                    path: "settings/user",
                    element: <UserListScreen />,
                },
                {
                    path: "coming-soon",
                    element: <ComingSoonFeatureScreen />
                },
                {
                    path: "unavailable",
                    element: <ShopUnavailableScreen />
                }
            ]
        },
    ]
);