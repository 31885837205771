import { useEffect, useState } from "react"

import DropdownChip from "./../DropDownChip"
import { InstallmentPlanDto } from "../../client/motalvip-apis/generated"

type LoanRegistrationStatusChipProps = {
    status?: InstallmentPlanDto.registrationStatus
    selectable?: boolean
    onSelect?: (option: InstallmentPlanDto.registrationStatus) => void;
    disabled?: boolean;
}

export const loanRegistrationStatusOptions = [
    { value: InstallmentPlanDto.registrationStatus.PENDING_CUSTOMER_INFO, display: 'ข้อมูลลูกค้า' },
    { value: InstallmentPlanDto.registrationStatus.PENDING_CUSTOMER_DOCUMENTS, display: 'เอกสารลูกค้า' },
    { value: InstallmentPlanDto.registrationStatus.PENDING_LOAN_AND_DEVICE_INFO, display: 'ข้อมูลสินเชื่อเครื่อง' },
    { value: InstallmentPlanDto.registrationStatus.PENDING_APPROVAL, display: 'รออนุมัติ' },
    { value: InstallmentPlanDto.registrationStatus.APPROVED, display: 'อนุมัติแล้ว' },
    { value: InstallmentPlanDto.registrationStatus.REJECTED, display: 'ปฏิเสธ' },
    { value: InstallmentPlanDto.registrationStatus.CANCEL, display: 'ยกเลิก' },
];

export const LoanRegistrationStatusChip: React.FC<LoanRegistrationStatusChipProps> = ({ disabled, status, selectable = false, onSelect = () => { } }) => {

    const [selectedOption, setSelectedOption] = useState<InstallmentPlanDto.registrationStatus | undefined>(status);

    useEffect(() => {
        setSelectedOption(status)
    }, [status])

    const handleSelect = (option: InstallmentPlanDto.registrationStatus) => {
        setSelectedOption(option);
        onSelect(option);
    }

    const colorMapping = {
        'ข้อมูลลูกค้า': '#757575',
        'เอกสารลูกค้า': '#757575',
        'ข้อมูลสินเชื่อเครื่อง': '#757575',
        'รออนุมัติ': '#1e88e5',
        'อนุมัติแล้ว': '#43a047',
        'ปฏิเสธ': '#e53935',
        'ยกเลิก': '#eeeeee',
    };

    return (
        <DropdownChip
            disabled={disabled}
            label={loanRegistrationStatusOptions.find(o => o.value === selectedOption)!.display}
            options={loanRegistrationStatusOptions}
            onSelect={handleSelect}
            colorMapping={colorMapping}
            selectable={selectable}
            variant='outlined'
        />
    )

}