import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Stack,
  TextField,
} from "@mui/material";
import { useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import {
  InstallmentPlanDto,
  MasterDeviceDto,
} from "../../client/motalvip-apis/generated";
import { useSearchMasterDevices } from "../../client/query-client";
import { useDebouncedValue } from "../../util/useDebouncedValue";
import CustomInputLabel from "../CustomInputLabel";

const DEFAULT_LIMIT = 10;
const DEBOUNCE_DELAY = 250;

const TogglableLabel = ({
  toggleFormModeText,
  onModeChanged,
  label,
}: {
  toggleFormModeText: string;
  onModeChanged: () => void;
  label: string;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 1,
      }}
    >
      <CustomInputLabel>{label}</CustomInputLabel>
      <Button onClick={onModeChanged}>{toggleFormModeText}</Button>
    </Box>
  );
};

const SearchableDeviceField = ({
  onSelected,
}: {
  onSelected?: (device: MasterDeviceDto) => void;
}) => {
  const [inputValue, setInputValue] = useState("");
  const debouncedInputValue = useDebouncedValue(inputValue, DEBOUNCE_DELAY);
  const { data } = useSearchMasterDevices({
    limit: DEFAULT_LIMIT,
    query: debouncedInputValue,
    // For recommendation, just use page 1.
    page: 0,
  });

  const [keyedOptions, names]: [Record<string, MasterDeviceDto>, string[]] =
    useMemo(() => {
      if (!data || data.data.length === 0) return [{}, []];

      const map: Record<string, MasterDeviceDto> = {};
      const names: string[] = [];
      for (const device of data.data) {
        const key = `${device.brandName} ${device.displayName}`;
        names.push(key);
        map[key] = device;
      }

      return [map, names];
    }, [data]);

  const onComboListSelected = (value: string | null) => {
    if (!value) return;
    const device = keyedOptions[value];
    if (!device) return;

    onSelected?.(device);
  };

  return (
    <Autocomplete
      disablePortal
      options={names}
      sx={{ width: 300 }}
      filterOptions={(options) => options}
      onChange={(_e, v) => {
        onComboListSelected(v);
      }}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => <TextField {...params} label="ค้นหา" />}
    />
  );
};

export const InstallmentMasterDeviceModelField = ({
  form,
}: {
  form: UseFormReturn<InstallmentPlanDto, any, undefined>;
}) => {
  const [mode, setMode] = useState<"combobox" | "freetext">("combobox");

  const onModeChanged = () => {
    setMode((prev) => (prev === "combobox" ? "freetext" : "combobox"));
    form.setValue("product.brand", undefined);
    form.setValue("product.model", undefined);
    form.setValue("product.color", undefined);
  };

  const toggleFormModeText = mode === "combobox" ? "อื่นๆ" : "เลือกจากรายการ";

  return mode === "combobox" ? (
    <FormControl fullWidth>
      <TogglableLabel
        toggleFormModeText={toggleFormModeText}
        onModeChanged={onModeChanged}
        label="รุ่นโทรศัพท์"
      />
      <SearchableDeviceField
        onSelected={(device) => {
          form.setValue("product.brand", device.brandName);
          form.setValue("product.model", device.displayName);
        }}
      />
    </FormControl>
  ) : (
    <>
      <FormControl fullWidth>
        <TogglableLabel
          toggleFormModeText={toggleFormModeText}
          onModeChanged={onModeChanged}
          label="แบรนด์"
        />
        <TextField
          {...form.register("product.brand")}
          size="small"
          variant="outlined"
        />
      </FormControl>
      <Stack direction="row" gap={2}>
        <FormControl fullWidth>
          <CustomInputLabel>รุ่น</CustomInputLabel>
          <TextField
            {...form.register("product.model")}
            size="small"
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth>
          <CustomInputLabel>สี</CustomInputLabel>
          <TextField
            {...form.register("product.color")}
            size="small"
            variant="outlined"
          />
        </FormControl>
      </Stack>
    </>
  );
};
