import { Box, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { MaterialReactTable, MRT_ColumnDef, MRT_SortingState, useMaterialReactTable } from "material-react-table";
import { useMemo, useState } from "react";
import { InstallmentTerm, InstallmentPaymentDto } from "../../client/motalvip-apis/generated";
import { useShopContext } from "../../context/ShopContextProvider";


type Props = {
    data: Array<InstallmentTerm> | undefined,
    showPayment?: boolean,
    payment?: InstallmentPaymentDto[]

}

export const InstallmentTermTable = (props: Props) => {
    const currencyFormat = Intl.NumberFormat('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'sequenceNumber', desc: false }]);
    const shopCtx = useShopContext();
    const columns = useMemo<MRT_ColumnDef<InstallmentTerm>[]>(
        () => [
            {
                id: 'date',
                accessorFn: (row: InstallmentTerm) => {
                    return <Typography>{row.termNumber}</Typography>
                }, //simple recommended way to define a column
                header: 'งวด',
                enableHiding: false,
                enablePinning: false,
                enableSorting: false,
                size: 100,
                Cell: ({ renderedCellValue, row }) => (
                    <Box width={100}>
                        <Typography noWrap >
                            {renderedCellValue}
                        </Typography>
                    </Box>
                ),
            },
            {
                id: 'shopName',
                accessorFn: (row: InstallmentTerm) => {
                    return <Typography>{dayjs(row.dueDate).locale("th").format('DD MMM YYYY')}</Typography>
                }, //simple recommended way to define a column
                header: 'วันที่ครบกำหนด',
                enableHiding: false,
                enablePinning: false,
                enableSorting: false,
                Cell: ({ renderedCellValue, row }) => (
                    <Box >
                        <Typography noWrap >
                            {renderedCellValue}
                        </Typography>
                    </Box>
                ),
            },
            {
                id: 'dueAmount',
                accessorFn: (row: InstallmentTerm) => <Typography >{currencyFormat.format(row.dueAmount || 0)}</Typography>,
                header: 'ค่างวด',
                enableHiding: false,
                enablePinning: false,
                muiTableBodyCellProps: {
                    align: 'right',
                },
                grow: false,
                enableSorting: false,
            },
            {
                id: 'paidDueAmount',
                accessorFn: (row: InstallmentTerm) => <Typography sx={{ fontWeight: '600', color: '#0b57d0' }}>{currencyFormat.format(row.paidDueAmount || 0)}</Typography>,
                header: 'ค่างวดจ่ายแล้ว',
                enableHiding: false,
                enablePinning: false,
                muiTableBodyCellProps: {
                    align: 'right',
                },
                grow: false,
                enableSorting: false,
            },
            {
                id: 'fineAmount',
                accessorFn: (row: InstallmentTerm) => <Typography color={'#e70000'} >{currencyFormat.format(row.fineAmount || 0)}</Typography>,
                header: 'ค่าปรับที่ควรเก็บ',
                enableHiding: false,
                enablePinning: false,
                muiTableBodyCellProps: {
                    align: 'right',
                },
                grow: false,
                enableSorting: false,
            },
            {
                id: 'paidFineAmount',
                accessorFn: (row: InstallmentTerm) => <Typography sx={{ fontWeight: '600', color: '#0b57d0' }}>{currencyFormat.format(row.paidFineAmount || 0)}</Typography>, //simple recommended way to define a column
                header: 'ค่าปรับจ่ายแล้ว',
                enableHiding: false,
                enablePinning: false,
                muiTableBodyCellProps: {
                    align: 'right',
                },
                grow: false,
                enableSorting: false,
            },

        ],
        [currencyFormat],
    );

    const table = useMaterialReactTable({
        columns: columns,
        data: props.data || [],
        layoutMode: 'semantic',
        enablePagination: false,
        enableColumnPinning: true,
        enableGlobalFilter: false,
        enableColumnFilters: false,
        enableSorting: true,
        enableHiding: false,
        enableColumnActions: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableColumnVirtualization: false,
        enableColumnOrdering: false,
        enableBottomToolbar: false,
        positionGlobalFilter: 'left',
        enableTopToolbar: false,
        manualSorting: true,
        paginationDisplayMode: 'pages',
        onSortingChange: setSorting,
        localization: {
            rowsPerPage: "จำนวนต่อหน้า"
        },
        manualPagination: true,
        initialState: {
            showGlobalFilter: false,
            columnPinning: {
                right: ['mrt-row-actions'],
            }
        },
        state: {
            sorting,
            columnVisibility: {
                'totalProfit': shopCtx.isOwner,
                'paidDueAmount': props.showPayment || false,
                'paidFineAmount': props.showPayment || false,
                'fineAmount': props.showPayment || false
            },
        },
        enableRowActions: false,
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: '', //change header text
                size: 12, //make actions column wider
            },
        },
        muiTableHeadCellProps: {
            sx: {
                borderRight: '1px solid #e0e0e0', //add a border between columns
                backgroundColor: '#455a64',
                color: 'white',
                fontWeight: '500'
            },
        },
        muiTableBodyProps: {
            sx: {
                //stripe the rows, make odd rows a darker color
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td': {
                    backgroundColor: 'grey.100',
                },
            },
        },
        muiTableBodyCellProps: {
            sx: {
                borderRight: '1px solid #eeeeee', //add a border between columns
            },
        },
    });

    return (
        <>
            {props.data && props.data.length > 0 ?
                <MaterialReactTable table={table} />
                :
                <Stack width='100%' my={4} justifyContent='center' alignItems='center'>
                    <Typography>ไม่มีข้อมูล</Typography>
                </Stack>
            }
        </>
    )
}