import { SignUp } from "@clerk/clerk-react";
import { Stack } from "@mui/material";

export const SignUpScreen = () => {

    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
                background: `url(${process.env.PUBLIC_URL + '/motalvip-preview.jpg'}) no-repeat`,
                backgroundSize: 'cover',
                backgroundPosition: 'center 70%',
                height: '100vh'
            }}>
            <SignUp signInUrl={`/sign-in`} />
        </Stack>
    )
};