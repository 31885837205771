/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GenerateLoanQrPaymentRequest } from '../models/GenerateLoanQrPaymentRequest';
import type { InstallmentPlanDto } from '../models/InstallmentPlanDto';
import type { PaginatedMasterDeviceDto } from '../models/PaginatedMasterDeviceDto';
import type { ShopDto } from '../models/ShopDto';
import type { ShopPaymentChannelDto } from '../models/ShopPaymentChannelDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns string OK
     * @throws ApiError
     */
    public generateQrPaymentRequest({
        requestBody,
    }: {
        requestBody: GenerateLoanQrPaymentRequest,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/v2/shops/payment/qr',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopDto OK
     * @throws ApiError
     */
    public getShopPublicProfile({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<ShopDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/shops/{shopId}',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopPaymentChannelDto OK
     * @throws ApiError
     */
    public getShopPublicPaymentChannels({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<Array<ShopPaymentChannelDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/shops/{shopId}/payment-channels',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public getPublicLoanById({
        loanId,
    }: {
        loanId: string,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/shops/loans/{loanId}',
            path: {
                'loanId': loanId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public triggerJob(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/migrate/migrate-users',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public triggerJobMigrateShops(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/migrate/migrate-shops',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public triggerJobMigratePayments(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/migrate/migrate-payments',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public triggerJobMigrateLoans(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/migrate/migrate-loans',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public triggerJobMigrateCustomers(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/migrate/migrate-customers',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public triggerJobMigrateCustomers1({
        customerId,
    }: {
        customerId: string,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/migrate/migrate-customers/{customerId}',
            path: {
                'customerId': customerId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedMasterDeviceDto OK
     * @throws ApiError
     */
    public getDevices({
        query,
        limit,
        page,
    }: {
        query: string,
        limit: number,
        page: number,
    }): CancelablePromise<PaginatedMasterDeviceDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/master/devices',
            query: {
                'query': query,
                'limit': limit,
                'page': page,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
