import {
    keepPreviousData,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useDialog } from "../../context/DialogContext";
import { useBackendApiClient } from "../motalvip-apis";
import {
    InstallmentPaymentDocumentDto,
    InstallmentPaymentDto,
    InstallmentPlanDocumentDto,
    InstallmentPlanDto,
    ShopDashboardRequest,
    ShopDto,
    ShopUserInvitationRequest,
    ShopUserRemoveRequest,
    StepUpdateLoanDraftApproveLoanRequest,
    StepUpdateLoanDraftRejectLoanRequest,
} from "../motalvip-apis/generated";

//query key enum
export enum QueryKey {
    SHOPS = "SHOPS",
    SHOP = "SHOP",
    LOAN = "LOAN",
    LOANS = "LOANS",
    PAYMENT = "PAYMENT",
    USER = "USER",
    MASTER = "MASTER",
}

export const useUserMeQuery = (userId?: string) => {
    const backendApiClient = useBackendApiClient();
    return useQuery({
        queryKey: [userId],
        queryFn: () => backendApiClient.user.me(),
    });
};

export const useUserShopsQuery = (userId?: string) => {
    const backendApiClient = useBackendApiClient();
    return useQuery({
        queryKey: [userId],
        queryFn: () => backendApiClient.shop.getUserShops(),
        enabled: !!userId,
    });
};

export const useShopsQuerySuspense = () => {
    const apiClient = useBackendApiClient();
    return useQuery({
        queryKey: [QueryKey.SHOPS],
        queryFn: () => apiClient.shop.getUserShops(),
    });
};
export const useShopQuery = (shopId: string) => {
    const apiClient = useBackendApiClient();
    return useQuery({
        queryKey: [QueryKey.SHOP, shopId, "query"],
        queryFn: () => apiClient.shop.getShopById({ shopId: shopId }),
        refetchOnWindowFocus: true,
    });
};
export const usePaymentChannelQuery = (shopId: string) => {
    const apiClient = useBackendApiClient();
    return useQuery({
        queryKey: [QueryKey.SHOPS, shopId, "PaymentChannel"],
        queryFn: () => apiClient.shop.getShopPaymentChannels({ shopId }),
    });
};

// export const usePhoneNumberQuery = (shopId: string) => {
//     const motalClient2 = useBackendApiClient()
//     return useQuery({ queryKey: [QueryKey.SHOP, 'phoneNumber', shopId], queryFn: () => motalClient2.shop.getShopPhoneNumbers({ shopId: shopId }) })
// }
export const useShopUsersQuery = (shopId: string) => {
    const apiClient = useBackendApiClient();
    return useQuery({
        queryKey: [QueryKey.SHOP, "shopUsers", shopId],
        queryFn: () => apiClient.shop.getShopUsers({ shopId: shopId }),
    });
};
export const useShopUserInvitationMutation = () => {
    const apiClient = useBackendApiClient();
    return useMutation({
        mutationKey: [QueryKey.SHOP],
        mutationFn: (req: ShopUserInvitationRequest) =>
            apiClient.shop.createShopUserInvitation({
                requestBody: {
                    shopId: req.shopId,
                    email: req.email,
                    role: req.role,
                },
            }),
    });
};
export const useShopUserRemoveMutation = () => {
    const apiClient = useBackendApiClient();
    return useMutation({
        mutationKey: [QueryKey.SHOP],
        mutationFn: (req: ShopUserRemoveRequest) =>
            apiClient.shop.removeShopUser({
                requestBody: {
                    shopId: req.shopId,
                    emailAddress: req.emailAddress,
                },
            }),
    });
};
export const useCreateShopMutation = () => {
    // const motalClient = useMotalClient()
    const apiClient = useBackendApiClient();
    return useMutation({
        mutationKey: [QueryKey.SHOPS],
        mutationFn: (req: ShopDto) =>
            apiClient.shop.createShop({ requestBody: req }),
    });
};

export const useInstallmentPlanQuery = (installmentPlanId?: string) => {
    const backendApiClient = useBackendApiClient();
    return useQuery({
        queryKey: [installmentPlanId],
        queryFn: () =>
            backendApiClient.installmentPlan.getInstallmentPlanById({
                loanId: installmentPlanId!,
            }),
        enabled: !!installmentPlanId,
    });
};

export const useInstallmentPlanStatusMutation = (
    installmentPlanDto?: InstallmentPlanDto
) => {
    const queryClient = useQueryClient();
    const backendApiClient = useBackendApiClient();
    return useMutation({
        mutationKey: [installmentPlanDto?.id],
        mutationFn: (status: InstallmentPlanDto.status) =>
            backendApiClient.installmentPlan.updateInstallmentPlanStatus({
                requestBody: {
                    ...installmentPlanDto!,
                    status: status,
                },
            }),
        onSuccess: () => {
            toast.success("บันทึกสำเร็จ");
            queryClient.invalidateQueries({ queryKey: [installmentPlanDto?.id] });
        },
        onError: (error) => {
            toast.success("เกิดความผิดพลาด");
        },
    });
};

export const useInstallmentPlanDeleteMutation = (
    installmentPlanId?: string
) => {
    const backendApiClient = useBackendApiClient();
    return useMutation({
        mutationKey: [installmentPlanId],
        mutationFn: () =>
            backendApiClient.installmentPlan.deleteInstallmentPlan({
                installmentPlanId: installmentPlanId || "",
            }),
    });
};

export const useCreateInstallmentPlanDocument = (
    installmentPlanId?: string
) => {
    const backendApiClient = useBackendApiClient();
    return useMutation({
        mutationKey: [installmentPlanId],
        mutationFn: (req: InstallmentPlanDocumentDto) =>
            backendApiClient.installmentPlan.createInstallmentPlanDocument({
                requestBody: req,
            }),
        retry: 0,
    });
};

export const useSearchHistory = (
    page: number,
    limit: number,
    search: string,
    enabled: boolean
) => {
    const apiClient = useBackendApiClient();
    return useQuery({
        enabled: enabled,
        queryKey: [QueryKey.LOAN, "history", search],
        queryFn: () =>
            apiClient.history.searchHistory({
                search: search,
                page: page,
                limit: limit,
            }),
    });
};

export const useLoanHistoryQuery = (
    customerIdentifier: string,
    enabled: boolean
) => {
    const apiClient = useBackendApiClient();
    return useQuery({
        enabled: enabled,
        queryKey: [QueryKey.LOAN, "history", customerIdentifier],
        queryFn: () =>
            apiClient.installmentPlan.getLoanCustomerHistory({
                requestBody: { customerIdentifier },
            }),
    });
};

export const useLoansQuery = (req: {
    shopId?: string;
    search?: string;
    scheduledDaysFrom?: number;
    scheduledDaysTo?: number;
    appointmentDaysFrom?: number;
    appointmentDaysTo?: number;
    transactionDateFrom?: string;
    transactionDateTo?: string;
    registrationDateFrom?: string;
    registrationDateTo?: string;
    customerIdentifier?: string;
    page?: number;
    limit?: number;
    status?: InstallmentPlanDto.status[];
    isAppointment?: boolean;
    registrationStatus?: InstallmentPlanDto.registrationStatus[];
    sortBy?: string;
    sort?: string;
}) => {
    const apiClient = useBackendApiClient();
    return useQuery({
        queryKey: [
            QueryKey.LOANS,
            req.shopId,
            req.search,
            req.page,
            req.scheduledDaysFrom,
            req.scheduledDaysTo,
            req.status,
            req.isAppointment,
            req.sortBy,
            req.sort,
            req.transactionDateFrom,
            req.transactionDateTo,
            req.registrationStatus,
            req.registrationDateFrom,
            req.registrationDateTo,
            req.customerIdentifier,
        ],
        queryFn: () =>
            apiClient.installmentPlan.searchLoans({
                requestBody: {
                    shopId: req.shopId,
                    downPaymentDateFrom: req.transactionDateFrom,
                    downPaymentDateTo: req.transactionDateTo,
                    limit: req.limit,
                    page: req.page,
                    status: req.status,
                    registrationStatus: req.registrationStatus,
                    registrationDateFrom: req.registrationDateFrom,
                    registrationDateTo: req.registrationDateTo,
                    scheduledDaysFrom: req.scheduledDaysFrom,
                    scheduledDaysTo: req.scheduledDaysTo,
                    appointmentDaysFrom: req.appointmentDaysFrom,
                    appointmentDaysTo: req.appointmentDaysTo,
                    searchText: req.search,
                    isAppointment: req.isAppointment,
                    sort: req.sort,
                    sortBy: req.sortBy,
                    customerIdentifier: req.customerIdentifier,
                },
            }),
    });
};

export const useInstallmentPlanNoteUpdateMutation = (installmentPlan?: InstallmentPlanDto) => {
    const dialog = useDialog();
    const apiClient = useBackendApiClient();
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: [installmentPlan?.id],
        mutationFn: (note: string) =>
            apiClient.installmentPlan.updateInstallmentPlanNote({
                requestBody: { ...installmentPlan!, note: note },
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [installmentPlan?.id] });
        },
        onError: (error) => {
            dialog.showDialog({
                title: "Error",
                description: error.message || "An error occurred",
                onConfirm: () => { },
            });
        },
    });
}

export const useApproveLoanMutation = () => {
    const dialog = useDialog();
    const apiClient = useBackendApiClient();
    return useMutation({
        mutationFn: (request: StepUpdateLoanDraftApproveLoanRequest) =>
            apiClient.installmentPlan.stepUpdateLoanDraftApproveLoan({
                requestBody: request,
            }),
        onSuccess: () => {
            toast.success("บันทึกสำเร็จ");
        },
        onError: (error) => {
            dialog.showDialog({
                title: "เกิดความผิดพลาด",
                description: "",
                onConfirm: () => { },
            });
        },
    });
};

export const useRejectLoanMutation = () => {
    const dialog = useDialog();
    const apiClient = useBackendApiClient();
    return useMutation({
        mutationFn: (request: StepUpdateLoanDraftRejectLoanRequest) =>
            apiClient.installmentPlan.stepUpdateLoanDraftRejectLoan({
                requestBody: request,
            }),
        onSuccess: () => {
            toast.success("บันทึกสำเร็จ");
        },
        onError: (error) => {
            dialog.showDialog({
                title: "เกิดความผิดพลาด",
                description: "",
                onConfirm: () => { },
            });
        },
    });
};

// export const useUpdateLoanMutation = () => {
//     const dialog = useDialog();
//     const motalClient = useMotalClient()
//     return useMutation({
//         mutationKey: [QueryKey.LOANS],
//         mutationFn: (req: { loan: LoanEvent, loanId: string }) => motalClient.loan.updateLoan({ loanId: req.loanId, requestBody: req.loan }),
//         onError: (error) => {
//             dialog.showDialog({
//                 title: 'เกิดความผิดพลาด',
//                 description: '',
//                 onConfirm: () => { }

//             })
//         }
//     })

// }
// export const useBlacklistSearchQuery = (req: {
//     shopId?: string;
//     search?: string;
//     scheduledDaysFrom?: number;
//     scheduledDaysTo?: number;
//     page?: number;
//     limit?: number;
// }, enabled: boolean = true) => {
//     const motalClient = useBackendApiClient()
//     return useQuery({
//         queryKey: [QueryKey.LOANS, 'blacklist', req.page, req.limit, req.search, req.scheduledDaysFrom, req.scheduledDaysTo],
//         queryFn: () => motalClient.loan.searchBlacklistLoans(req),
//         enabled: enabled
//     })
// }

// export const useCreateLoanMutation = () => {
//     const dialog = useDialog();
//     const motalClient = useMotalClient()
//     return useMutation({
//         mutationKey: [QueryKey.LOANS, 'create'],
//         mutationFn: (req: LoanEvent) => motalClient.loan.createLoan({ requestBody: req }),
//         onError: (error) => {
//             dialog.showDialog({
//                 title: 'Error',
//                 description: error.message || 'An error occurred',
//                 onConfirm: () => { }

//             })
//         }
//     })
// }

// export const useCalculatePaymentScheduleQuery = (req: CalculateRepaymentScheduleRequest, enabled: boolean = true) => {
//     const motalClient = useMotalClient()
//     return useQuery({
//         queryKey: [QueryKey.PAYMENT, req.downPaymentAmount, JSON.stringify(req)],
//         queryFn: () => motalClient.payment.previewRepayment({ requestBody: req }),
//         enabled: enabled && req.downPaymentAmount !== 0 &&
//             !!req.repaymentTermAmount &&
//             !!req.repaymentTermCount &&
//             !!req.repaymentTermFrequency &&
//             req.repaymentTermFrequencyType !== undefined &&
//             req.firstRepaymentDate !== undefined &&
//             req.downPaymentDate !== undefined
//     })
// }

export const usePaymentsQuery = (
    loanId: string,
    shopId: string,
    limit: number,
    enabled: boolean = true
) => {
    const apiClient = useBackendApiClient();
    return useQuery({
        queryKey: [QueryKey.PAYMENT, loanId],
        queryFn: () =>
            apiClient.installmentPayment.searchPayment({ loanId, shopId, limit }),
        enabled: !!loanId && !!shopId && enabled,
    });
};
export const usePaymentQuery = (paymentId: string, enabled: boolean = true) => {
    const apiClient = useBackendApiClient();
    return useQuery({
        queryKey: [QueryKey.PAYMENT, paymentId],
        queryFn: () =>
            apiClient.installmentPayment.getPaymentById({ paymentId: paymentId }),
        enabled: !!paymentId && enabled,
    });
};


export const usePaymentCreateMutation = (installmentPlanId: string) => {
    const dialog = useDialog();
    const apiClient = useBackendApiClient();
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: [installmentPlanId],
        mutationFn: (req: { payment: InstallmentPaymentDto }) =>
            apiClient.installmentPayment.createTermPayment({
                requestBody: req.payment,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [installmentPlanId] });
        },
        onError: (error) => {
            dialog.showDialog({
                title: "Error",
                description: error.message || "An error occurred",
                onConfirm: () => { },
            });
        },
    });
};

export const useInstallmentPaymentDocumentCreateMutation = (installmentPlanId: string) => {
    const dialog = useDialog();
    const apiClient = useBackendApiClient();
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: [installmentPlanId],
        mutationFn: (req: InstallmentPaymentDocumentDto) =>
            apiClient.installmentPayment.createInstallmentPaymentDocument({
                requestBody: req,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [installmentPlanId] });
        },
        onError: (error) => {
            dialog.showDialog({
                title: "Error",
                description: error.message || "An error occurred",
                onConfirm: () => { },
            });
        },
    });
}

export const useSearchMasterDevices = ({
    limit,
    query,
    page,
}: {
    query: string;
    limit: number;
    page: number;
}) => {
    const apiClient = useBackendApiClient();
    const result = useQuery({
        queryKey: [QueryKey.MASTER, query],
        queryFn: () => apiClient.public.getDevices({ limit, query, page }),
        placeholderData: keepPreviousData,
    });
    return result;
};

export const usePaymentDeleteMutation = (installmentPlanId: string) => {
    const dialog = useDialog();
    const apiClient = useBackendApiClient();
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: [installmentPlanId],
        mutationFn: (req: { payment: InstallmentPaymentDto }) =>
            apiClient.installmentPayment.deletePayment({ requestBody: req.payment }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [installmentPlanId] });
        },
        onError: (error) => {
            dialog.showDialog({
                title: "Error",
                description: error.message || "An error occurred",
                onConfirm: () => { },
            });
        },
    });
};

// export const useSummaryQuery = (shopId: string, year: number) => {
//     const motalClient = useBackendApiClient()
//     return useQuery({ queryKey: [QueryKey.SHOP, shopId, year], queryFn: () => motalClient.shop.summary({ shopId, year }) })
// }

// export const usePaymentQueryByShopId = (shopId: string, page: number = 1) => {
//     const motalClient = useBackendApiClient()
//     return useQuery({ queryKey: [QueryKey.PAYMENT, shopId, page], queryFn: () => motalClient.payment.searchPayment({ shopId, page },) })
// }
export const useShopUpdateMutation = (shopId: string) => {
    const dialog = useDialog();
    const apiClient = useBackendApiClient();
    const queryClient = useQueryClient();
    return useMutation({
        // mutationKey: [QueryKey.SHOP, shopId,'update'],
        mutationFn: (req: ShopDto) =>
            apiClient.shop.updateShop({ requestBody: req }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKey.SHOP, shopId] });
        },
        onError: (error) => {
            dialog.showDialog({
                title: "Error",
                description: error.message || "An error occurred",
                onConfirm: () => { },
            });
        },
    });
};
export const useShopDashboardQuery = (
    shopId: string,
    req: ShopDashboardRequest
) => {
    const motalClient2 = useBackendApiClient();
    return useQuery({
        queryKey: [QueryKey.SHOP, shopId, "dashboard"],
        queryFn: () =>
            motalClient2.shop.getShopDashboard({ shopId: shopId, requestBody: req }),
    });
};
