import { Box, Button, Dialog, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useState } from "react"
import toast from "react-hot-toast"
import { InstallmentPlanDto } from "../../client/motalvip-apis/generated"
import { useInstallmentPlanNoteUpdateMutation } from "../../client/query-client"

interface InstallmentPlanNoteDialogProps {
    installmentPlanDto?: InstallmentPlanDto,
    open: boolean,
    onClose: () => void,
}
export const InstallmentPlanNoteDialog = (props: InstallmentPlanNoteDialogProps) => {
    const installmentPlanNoteUpdateMutation = useInstallmentPlanNoteUpdateMutation(props.installmentPlanDto)

    const [note, setNote] = useState<string>(props.installmentPlanDto?.note || '-')

    const onSave = () => {
        installmentPlanNoteUpdateMutation.mutate(note, {
            onSuccess: () => {
                toast.success('บันทึกข้อมูลสำเร็จ')
                props.onClose()
            },
            onError: () => {
                toast.error('เกิดข้อผิดพลาด')
                props.onClose()
            }
        })
    }

    const onCloseDialog = () => {
        props.onClose();
    }

    const theme = useTheme();
    const fullWidth = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            fullWidth={fullWidth}
            maxWidth={fullWidth ? false : 'md'}
            open={props.open} onClose={props.onClose}
            PaperProps={{ sx: { borderRadius: 2 } }}
        >
            <Box sx={{ bgcolor: 'white', width: fullWidth ? '100%' : theme.breakpoints.values.md }} >
                <Box sx={{ p: 2, justifyContent: 'space-between', bgcolor: '#263238' }}>
                    <Stack direction='column' alignItems='center' sx={{ width: '100%' }}>
                        <Typography variant="h5" color='white'>{`บันทึกโน๊ต`}</Typography>
                    </Stack>
                </Box>
                <Box p={2}>
                    <TextField
                        fullWidth
                        multiline
                        rows={10}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                    />
                </Box>
                <Box sx={{ p: 2, pt: 0, bgcolor: 'white', width: '100%' }} >
                    <Stack direction='row' gap={1} justifyContent='center'>
                        <Button variant='outlined' size='large'
                            onClick={onCloseDialog}
                            sx={{
                                width: {
                                    xs: '100%', sm: '100%', md: 200
                                },
                            }}>ยกเลิก</Button>
                        <Button variant='contained' size='large'
                            onClick={onSave}
                            sx={{
                                width: {
                                    xs: '100%', sm: '100%', md: 200
                                },
                            }}>บันทึก</Button>
                    </Stack>
                </Box>
            </Box>
        </Dialog>
    )
}