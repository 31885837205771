import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { InstallmentPlanDto } from "../../client/motalvip-apis/generated"
import { useInstallmentPlanDeleteMutation } from "../../client/query-client"

export const InstallmentPlanDeleteDialog = (props: {
    open: boolean,
    onClose: () => void,
    installmentPlanDto?: InstallmentPlanDto,
}) => {
    const nav = useNavigate()
    const installmentPlanDeleteMutation = useInstallmentPlanDeleteMutation(props.installmentPlanDto?.id || '')

    const onDelete = () => {
        installmentPlanDeleteMutation.mutate(undefined, {
            onSuccess: () => {
                toast.success('ลบข้อมูลสำเร็จ')
                nav(-1)
                props.onClose()
            },
            onError: () => {
                toast.error('เกิดข้อผิดพลาด')
                props.onClose()
            }
        })
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} >
            <DialogTitle>ยืนยันลบสินเชื่อ</DialogTitle>
            <DialogContent>
                <Typography>ต้องการลบใช่หรือไม่?</Typography>
                <Typography>ข้อมูลของสินเชื่อนี้จะถูกลบทั้งหมด</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>ยกเลิก</Button>
                <Button color='error' onClick={() => onDelete()}>ลบ</Button>
            </DialogActions>
        </Dialog>
    )
}