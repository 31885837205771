
import { Box, Divider, List, ListItem, Toolbar, Typography } from '@mui/material';
import { useShopContext } from '../../context/ShopContextProvider';
import { ShopMenuItem } from './ShopMenuItem';

type ShopDrawerProps = {
    shopId: string,
}

export const ShopDrawer = (props: ShopDrawerProps) => {
    const shopCtx = useShopContext()

    return (
        <div>
            <Toolbar />
            <List sx={{ p: 0, color: '#9e9e9e' }}>
                <Box
                    sx={{
                        pt: 2,
                        pb: 1,
                        px: 3,
                    }}
                >
                    <Typography variant='body2'>ดำเนินงาน</Typography>
                </Box>
                {
                    shopCtx.menuData?.map((menu) => {
                        return (
                            <ListItem key={menu.label} disablePadding>
                                <ShopMenuItem
                                    menuId={menu.key}
                                    link={menu.key.replace(':shopId', props.shopId)}
                                    icon={menu.icon}
                                    label={menu.label}
                                    children={menu.children?.map(c => {
                                        return {
                                            menuId: c.key,
                                            link: c.key.replace(':shopId', props.shopId),
                                            label: c.label,
                                            isRequiredOwner: c.isRequiredOwner
                                        }
                                    })}
                                    disabled={menu.key === '/:shopId/coming-soon'}
                                />
                            </ListItem>
                        )
                    })
                }
                <Box sx={{ p: 2 }} />
                <Divider sx={{ backgroundColor: '#e0e0e0' }} />
                <Box
                    sx={{
                        pt: 2,
                        pb: 1,
                        px: 3
                    }}
                >
                    <Typography variant='body2'>ตั้งค่า</Typography>
                </Box>
                {
                    shopCtx.shopMenuData?.map((menu) => {
                        return (
                            <ListItem key={menu.label} disablePadding>
                                <ShopMenuItem
                                    menuId={menu.key}
                                    link={menu.key.replace(':shopId', props.shopId)}
                                    icon={menu.icon}
                                    label={menu.label}
                                    children={menu.children?.map(c => {
                                        return {
                                            menuId: c.key,
                                            link: c.key.replace(':shopId', props.shopId),
                                            label: c.label,
                                            isRequiredOwner: c.isRequiredOwner
                                        }
                                    })}
                                    disabled={menu.key === '/:shopId/coming-soon'}
                                />
                            </ListItem>
                        )
                    })
                }
            </List>
        </div >
    )
}