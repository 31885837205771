import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, Button, Card, CardContent, Dialog, FormControl, MenuItem, Select, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Location, useParams } from "react-router-dom";
import { InstallmentPaymentDocumentDto, InstallmentPaymentDto } from "../../client/motalvip-apis/generated";
import { useInstallmentPaymentDocumentCreateMutation, usePaymentChannelQuery, usePaymentCreateMutation } from "../../client/query-client";
import { CustomInputLabel } from "../common/CustomInputLabel";
import PaymentChannelRecord from '../PaymentChannelRecord';
import { InstallmentPaymentDocumentCard } from './InstallmentPaymentDocumentCard';

interface FileWithPreview {
    file: InstallmentPaymentDocumentDto;
    uploaded: boolean;
}


type InstallmentPaymentCreateDialogProps = {
    open: boolean,
    onClose: () => void,
    location?: Location,
    title?: string,
    defauleValues?: InstallmentPaymentDto,
    maxDueAmount?: number
    maxFineAmount?: number
};

export const InstallmentPaymentCreateDialog = (props: InstallmentPaymentCreateDialogProps) => {

    const { shopId, installmentPlanId } = useParams()
    const paymentChannelsQuery = usePaymentChannelQuery(shopId || '')

    const { control, handleSubmit, reset, register, setValue, formState: { errors } } = useForm<InstallmentPaymentDto>({
        defaultValues: {},
        mode: 'all'
    });

    const [documents, setDocuments] = useState<FileWithPreview[]>([]);

    const createPaymentMutation = usePaymentCreateMutation(installmentPlanId || '')
    const installmentPaymentDocumentCreateMutation = useInstallmentPaymentDocumentCreateMutation(installmentPlanId || '')
    const currencyFormat = Intl.NumberFormat('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });


    useEffect(() => {
        reset(props.defauleValues)
        setDocuments([])
    }, [props.defauleValues, reset])
    useEffect(() => {
        setValue('documents', documents.map(d => d.file))
    }, [documents, setValue])

    const onSubmit = () => {
        handleSubmit((data) => {
            createPaymentMutation.mutate({ payment: data }, {
                onSuccess: () => {
                    toast.success('บันทึกข้อมูลสำเร็จ')
                    props.onClose()
                },
                onError: (error) => {
                    toast.error('เกิดข้อผิดพลาด')
                    props.onClose()
                }
            });
        })()
    }

    const theme = useTheme();
    const fullWidth = useMediaQuery(theme.breakpoints.down('md'));

    const onDrop = (files: File[]) => {
        files.forEach(file => {
            installmentPaymentDocumentCreateMutation.mutate({
                originalName: file.name,
                type: InstallmentPaymentDocumentDto.type.PAYSLIP
            }, {
                onSuccess: async (res) => {
                    if (res.uploadUrl) {
                        setDocuments((prev) => [...prev, { file: res, uploaded: false }])
                        uploadFile(file, res.uploadUrl)
                    }
                },
                onError: (error) => {
                }
            })
        })
    }
    const uploadFile = async (file: File, uploadUrl: string) => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', uploadUrl, true);
            xhr.setRequestHeader('Content-Type', file.type);
            xhr.onload = () => {
                if (xhr.status === 200) {
                    setDocuments((prev) =>
                        prev.map((f) =>
                            f.file.originalName === file.name ? { ...f, uploaded: true } : f
                        )
                    );
                } else {
                    console.error('Upload failed', xhr.responseText);
                }
            };
            xhr.send(file);
        } catch (error) {
            console.error('Error uploading file', error);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const onCloseDialog = () => {
        reset();
        props.onClose();
    }

    return (

        <Dialog
            fullWidth={fullWidth}
            maxWidth={fullWidth ? false : 'md'}
            open={props.open}
            onClose={onCloseDialog}
            PaperProps={{ sx: { borderRadius: 2 } }}
        >
            <Box sx={{ bgcolor: 'white', width: fullWidth ? '100%' : theme.breakpoints.values.md }} >
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"th"}>
                    <Box sx={{ p: 2, justifyContent: 'space-between', bgcolor: '#263238' }}>
                        <Stack direction='column' alignItems='center' sx={{ width: '100%' }}>
                            <Typography variant="h5" color='white'>{props.title || `บันทึกการจ่ายเงิน`}</Typography>
                        </Stack>
                    </Box>
                    <Box sx={{ p: 2, width: '100%', bgcolor: 'white' }}>
                        <Card sx={{ my: 1, width: { md: '100%' } }}>
                            <CardContent sx={{ padding: 2 }}>
                                <Stack direction={'column'} gap={2}>
                                    <Typography variant="h6">ข้อมูลการจ่ายเงิน</Typography>
                                    <Stack direction={{ lg: 'row' }} gap={2}>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>วันที่รับจ่าย</CustomInputLabel>
                                            <Controller control={control}
                                                name="transactionAt"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        format="DD/MM/YYYY"
                                                        value={field.value ? dayjs(field.value) : null}
                                                        onChange={(date) => field.onChange(date ? date.format('YYYY-MM-DD') : '')}
                                                        slotProps={{ textField: { size: 'small', variant: 'outlined' } }} />
                                                )} />
                                            {errors.transactionAt && <Typography color="error">{errors.transactionAt.message}</Typography>}
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>งวดที่</CustomInputLabel>
                                            <Stack direction='row'>
                                                <Controller
                                                    control={control}
                                                    name='installmentTermNumber'
                                                    render={({ field }) => (
                                                        <TextField type="number" {...field} size='small' variant="outlined" disabled />
                                                    )}
                                                />
                                            </Stack>
                                        </FormControl>
                                    </Stack>
                                    <Stack direction={{ sm: 'column' }} gap={2}>
                                        <Stack direction={{ sm: 'column', md: 'row' }} gap={2}>
                                            <FormControl fullWidth error={errors.paidDueAmount?.message ? true : false}>
                                                <CustomInputLabel>ยอดจ่าย</CustomInputLabel>
                                                <Controller
                                                    name="paidDueAmount"
                                                    control={control}
                                                    rules={{
                                                        required: 'ต้องการจำนวนเงิน',
                                                        min: { value: 0, message: 'เป็นตัวเลขบวกเท่านั้น' },
                                                        max: { value: props.maxDueAmount || 999999999, message: `ยอดเงินต้องไม่เกิน ${currencyFormat.format(props.maxDueAmount || 999999999)}` }
                                                    }}
                                                    render={({ field }) => <TextField type="number" {...field} size='small' variant="outlined" />}
                                                />
                                                {errors.paidDueAmount && <Typography color="error">{errors.paidDueAmount.message}</Typography>}
                                            </FormControl>
                                            <FormControl fullWidth error={errors.paidFineAmount?.message ? true : false}>
                                                <CustomInputLabel>ยอดค่าปรับ</CustomInputLabel>
                                                <Controller
                                                    name="paidFineAmount"
                                                    control={control}
                                                    rules={{
                                                        min: { value: 0, message: 'เป็นตัวเลขบวกเท่านั้น' },
                                                        max: { value: props.maxFineAmount || 999999999, message: `ยอดเงินต้องไม่เกิน ${currencyFormat.format(props.maxFineAmount || 999999999)}` }
                                                    }}
                                                    render={({ field }) => <TextField type="number" {...field} size='small' variant="outlined" />}
                                                />
                                                {errors.paidFineAmount && <Typography color="error">{errors.paidFineAmount.message}</Typography>}
                                            </FormControl>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={{ sm: 'column' }} gap={2}>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>ช่องทางการรับเงิน</CustomInputLabel>
                                            <Controller
                                                control={control}
                                                name="shopPaymentChannelId"
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            labelId="paymentChannel-label"
                                                            id="paymentChannel"
                                                            size='small'
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                console.log(e.target.value)
                                                                setValue("shopPaymentChannelId", e.target.value)
                                                            }}
                                                        >
                                                            {paymentChannelsQuery.data?.map(p => (
                                                                <MenuItem key={p.id} value={`${p.id}`}>
                                                                    <Box width='100%'>
                                                                        <PaymentChannelRecord paymentChannel={p} hideIcon />
                                                                    </Box>
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    )
                                                }} />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>โน้ต</CustomInputLabel>
                                            <TextField {...register('note')} size='small' variant="outlined" />
                                        </FormControl>
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Card>

                        <Card sx={{ my: 1, width: { md: '100%' } }}>
                            <CardContent sx={{ padding: 2 }}>
                                <Stack direction='column' gap={2}>
                                    <Typography variant="h6">เพิ่มเติม</Typography>

                                    <CustomInputLabel>ไฟล์หลักฐาน</CustomInputLabel>
                                    <Box sx={{ width: '100%', }}>
                                        <Stack sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }} direction={'row'} gap={2}>
                                            {documents.map(doc =>
                                                <InstallmentPaymentDocumentCard
                                                    document={doc.file}
                                                    uploaded={doc.uploaded}
                                                    onDelete={() => { }}
                                                    onDownload={() => { }} />
                                            )}</Stack>
                                    </Box>
                                    <Box
                                        {...getRootProps()}
                                        sx={{
                                            width: '100%',
                                            border: '2px dashed darkgray',
                                            borderRadius: '8px',
                                            backgroundColor: 'white',
                                            padding: 2,
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: '#b3e5fc',
                                                color: '#03a9f4',
                                                borderColor: '#03a9f4',
                                            },
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100px'
                                        }}
                                    >
                                        <input {...getInputProps()} />
                                        <Stack direction='column' alignItems='center' gap={1}>
                                            <FileUploadIcon />
                                            <Typography>อัพโหลดไฟล์</Typography>
                                        </Stack>
                                    </Box>
                                    <Box sx={{ width: '100%', }}>
                                        <Stack sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }} direction={'row'} gap={2}>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Box>
                    <Box sx={{ p: 2, pt: 0, bgcolor: 'white', width: '100%' }} >
                        <Stack direction='row' gap={1} justifyContent='center'>
                            <Button variant='outlined' size='large'
                                onClick={onCloseDialog}
                                sx={{
                                    width: {
                                        xs: '100%', sm: '100%', md: 200
                                    },
                                }}>ยกเลิก</Button>
                            <Button variant='contained' size='large'
                                onClick={onSubmit}
                                sx={{
                                    width: {
                                        xs: '100%', sm: '100%', md: 200
                                    },
                                }}>บันทึก</Button>
                        </Stack>
                    </Box>
                </LocalizationProvider >
            </Box>
        </Dialog>
    );
};
