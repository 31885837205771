import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Avatar, Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Menu, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { ShopUserResponse } from "../../../client/motalvip-apis/generated";
import { useShopUserInvitationMutation, useShopUserRemoveMutation, useShopUsersQuery, useUserMeQuery } from "../../../client/query-client";
import { useDialog } from "../../../context/DialogContext";
import { Spin } from '../../../component/common/Spin';

type UserCardProps = {
    user: ShopUserResponse;
}
const UserCard = ({ user }: UserCardProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const userProfile = useUserMeQuery()
    const [openEditUserDialog, setOpenEditUserDialog] = useState<boolean>(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
    const handleCloseEditUserDialog = () => {
        setOpenEditUserDialog(false)
    }
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpenDeleteDialog(false)
    };
    const [role, setRole] = useState<ShopUserResponse.role | undefined>(user.role)
    const { shopId } = useParams();
    const dialog = useDialog()
    const userInvitationMutation = useShopUserInvitationMutation()
    const removeShopUserMutation = useShopUserRemoveMutation()
    const shopUsersQuery = useShopUsersQuery(shopId || '')
    const handleDeleteButtonClick = () => {
        setOpenDeleteDialog(true)
    }
    const handleConfirmEdit = () => {
        setOpenEditUserDialog(false)
        setAnchorEl(null);
        userInvitationMutation.mutate({
            shopId: shopId!,
            email: user.email,
            role: role
        }, {
            onSuccess: () => {
                toast.success('แก้ไขผู้ใช้งานสำเร็จ')
                shopUsersQuery.refetch()
            },
            onError: (error) => {
                dialog.showDialog({ title: 'เกิดข้อผิดพลาด', description: error.message, onConfirm: () => { } })
            }
        })
        setOpenEditUserDialog(false)
    }
    const handleConfirmDelete = () => {
        setOpenDeleteDialog(false)
        setAnchorEl(null);
        removeShopUserMutation.mutate({ shopId: shopId, emailAddress: user.email || '' }, {
            onSuccess: () => {
                toast.success('ลบผู้ใช้งานสำเร็จ')
                shopUsersQuery.refetch()
            },
            onError: (error) => {
                dialog.showDialog({ title: 'เกิดข้อผิดพลาด', description: error.message, onConfirm: () => { } })
            }
        })
        setOpenDeleteDialog(false)
    }
    return (

        <Card sx={{ width: { xs: '100%', md: '400px' } }}>
            <CardContent>
                <Dialog
                    fullWidth
                    open={openEditUserDialog}
                    onClose={handleCloseEditUserDialog}
                >
                    <DialogTitle>แก้ไขข้อมูลผู้ใช้งาน</DialogTitle>
                    <DialogContent >
                        <Stack gap={2}>
                            <Box>
                                <DialogContentText sx={{ pb: 1 }}>
                                    อีเมล
                                </DialogContentText>
                                <TextField
                                    value={user.email}
                                    disabled
                                    id="email"
                                    name="email"
                                    label="อีเมล"
                                    type="email"
                                    fullWidth
                                    variant="outlined"
                                />
                            </Box>
                            <Box>
                                <DialogContentText sx={{ pb: 1 }}>
                                    หน้าที่
                                </DialogContentText>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={role}
                                    onChange={(e) => { setRole(e.target.value as ShopUserResponse.role) }}
                                >
                                    <MenuItem value={ShopUserResponse.role.OWNER}>เจ้าของร้าน</MenuItem>
                                    <MenuItem value={ShopUserResponse.role.STAFF}>พนักงาน</MenuItem>
                                </Select>
                            </Box>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Stack direction='row' gap={2} p={2}>
                            <Button fullWidth size='large' variant="outlined" onClick={handleCloseEditUserDialog}>ยกเลิก</Button>
                            <Button onClick={handleConfirmEdit} fullWidth size='large' variant="contained" >บันทึก</Button>
                        </Stack>
                    </DialogActions>
                </Dialog>
                <Grid container spacing={2} alignItems='flex-start'>
                    <Grid xs={2} item >
                        <Box py={1}>
                            <Avatar alt={user.firstName} sx={{ width: 48, height: 48 }} src={''} />
                        </Box>
                    </Grid>
                    <Grid xs={8} item>
                        <Typography variant="h6" component="div">
                            {user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : '-'}
                        </Typography>
                        <Typography color="text.secondary">
                            {user.email}
                        </Typography>
                        <Typography variant="body2">
                            {user.role === ShopUserResponse.role.OWNER
                                ? 'เจ้าของร้าน'
                                : user.role === ShopUserResponse.role.STAFF
                                    ? 'พนักงาน'
                                    : '-'}
                        </Typography>
                    </Grid>
                    <Grid xs={2} item>
                        {!(user.email === userProfile.data?.primaryEmail) && <IconButton
                            onClick={handleClick}
                        ><MoreVertOutlinedIcon />
                        </IconButton>}
                        <Menu
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={() => setOpenEditUserDialog(true)}>แก้ไข</MenuItem>
                            <MenuItem onClick={handleDeleteButtonClick}>ลบ</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </CardContent>

            <Dialog open={openDeleteDialog} onClose={handleClose}>
                <DialogTitle>ลบผู้ใช้งาน</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        คุณต้องการลบ
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Stack direction='row' justifyContent='space-between' gap={1} p={1}>
                        <Button fullWidth variant='outlined' onClick={handleClose}>ยกเลิก</Button>
                        <Button onClick={handleConfirmDelete} fullWidth variant='contained' color="error">ลบ</Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

export const UserListScreen = () => {
    const [openUserDialog, setOpenUserDialog] = useState<boolean>(false)
    const form = useForm<{ email: string, role: ShopUserResponse.role }>({ defaultValues: { email: '', role: ShopUserResponse.role.STAFF } })

    const { shopId } = useParams();
    const createUserMutation = useShopUserInvitationMutation()
    const handleClose = () => {
        setOpenUserDialog(false);
    };
    const shopUsersQuery = useShopUsersQuery(shopId || '')
    const dialog = useDialog()
    const handleSubmit = () => {
        form.handleSubmit((data) => {
            setOpenUserDialog(false)
            createUserMutation.mutate({
                shopId: shopId,
                email: data.email,
                role: data.role
            }, {
                onSuccess: () => {
                    toast.success('เพิ่มผู้ใช้งานสำเร็จ')
                    form.reset({})
                    shopUsersQuery.refetch()
                },
                onError: (error) => {
                    dialog.showDialog({ title: 'เกิดข้อผิดพลาด', description: error.message, onConfirm: () => { } })
                }
            })
        })()
    }

    return (
        <Spin spinning={shopUsersQuery.isLoading}>
            <Box sx={{ p: 2 }}>
                <Dialog
                    fullWidth
                    open={openUserDialog}
                    onClose={handleClose}
                >
                    <DialogTitle>เพิ่มผู้ใช้งาน</DialogTitle>
                    <DialogContent >
                        <Stack gap={2}>
                            <Box>
                                <DialogContentText sx={{ pb: 1 }}>
                                    อีเมล
                                </DialogContentText>
                                <TextField
                                    {...form.register('email', { required: true })}
                                    required
                                    id="email"
                                    name="email"
                                    label="อีเมล"
                                    type="email"
                                    fullWidth
                                    variant="outlined"
                                />
                            </Box>
                            <Box>
                                <DialogContentText sx={{ pb: 1 }}>
                                    หน้าที่
                                </DialogContentText>
                                <Controller
                                    name="role"
                                    control={form.control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={field.value}
                                        >
                                            <MenuItem value={ShopUserResponse.role.OWNER}>เจ้าของร้าน</MenuItem>
                                            <MenuItem value={ShopUserResponse.role.STAFF}>พนักงาน</MenuItem>
                                        </Select>
                                    )}
                                />
                            </Box>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Stack direction='row' gap={2} p={2}>
                            <Button fullWidth size='large' variant="outlined" onClick={() => { setOpenUserDialog(false) }}>ยกเลิก</Button>
                            <Button onClick={handleSubmit} fullWidth size='large' variant="contained" >บันทึก</Button>
                        </Stack>
                    </DialogActions>
                </Dialog>
                <Stack gap={2}>
                    <Stack direction={{ lg: 'row' }} gap={2} justifyContent='space-between' alignItems='flex-start'>
                        <TextField
                            sx={{ backgroundColor: 'white' }}
                            variant="outlined"
                            placeholder="ค้าหา ชื่อ-นามสกุล, อีเมล"
                            fullWidth
                        />
                        <Button
                            variant='contained'
                            size='medium'
                            onClick={() => { setOpenUserDialog(true) }}
                            // onClick={() => useBlacklistSearchQuery.fet}
                            sx={{ height: '56px', width: { xs: '100%', lg: '15%' } }}
                        >
                            เพิ่มผู้ใช้งาน
                        </Button>
                    </Stack>
                    <Stack direction='row' gap={2} useFlexGap flexWrap="wrap" >
                        {shopUsersQuery.data?.map((user) => (
                            <UserCard key={user.email} user={user} />
                        ))}
                    </Stack>
                </Stack>
            </Box>
        </Spin>
    )
}