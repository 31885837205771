import { Box, Typography } from '@mui/material';
import dayjs from "dayjs";
import { MRT_ColumnDef } from "material-react-table";
// import { LoanResponse } from "../client/motal-apis/generated";
import { LoanRegistrationStatusMiniDisplay } from "../component/StatusChip/LoanRegistrationStatusMiniDisplay";
import { LoanRegistrationStatusChip } from '../component/StatusChip/LoanRegistrationStatusChip';
import { InstallmentPlanDto } from '../client/motalvip-apis/generated';

const currencyFormat = Intl.NumberFormat('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

enum ColumnDefType {
    APPROVAL,
    REGISTRATION,
    LOAN,
}

const LoanColumnDef = (type: ColumnDefType): MRT_ColumnDef<InstallmentPlanDto>[] => {
    return [
        {
            id: 'sequenceNumber',
            accessorFn: (row: InstallmentPlanDto) => <Typography>{row?.sequenceNumber} </Typography>,
            header: 'ลำดับ',
            enableHiding: false,
            enablePinning: false,
            enableSorting: true,
            size: 20,
            Header: ({ column }) => (
                <Box alignItems='center' width={40} >
                    <Typography noWrap >
                        {column.columnDef.header}
                    </Typography>
                </Box>
            ),
            muiTableBodyCellProps: {
                align: 'center'
            },
        },
        {
            id: 'status',
            accessorFn: (row: InstallmentPlanDto) => {
                if (type === ColumnDefType.REGISTRATION) {
                    return <LoanRegistrationStatusMiniDisplay data={row} />
                } else if (type === ColumnDefType.APPROVAL) {
                    return <LoanRegistrationStatusChip status={(row?.registrationStatus)} />
                }
            },
            header: 'สถานะการสมัคร',
            enableHiding: false,
            enableSorting: false,
            enablePinning: false,
        },
        {
            id: 'registrationDate',
            accessorFn: (row: InstallmentPlanDto) => {
                if (row?.registrationDate) {
                    return <Typography>{dayjs(row?.registrationDate).locale("th").format('DD MMM YYYY')}</Typography>
                } else {
                    return '-'
                };
            },
            header: 'วันที่สมัคร',
            enableHiding: false,
            enablePinning: false,
            enableSorting: true,
            size: 140,
        },
        {
            id: 'referenceId',
            accessorFn: (row: InstallmentPlanDto) => <Typography>{row?.referenceId} </Typography>,
            header: 'รหัสอ้างอิง',
            enableHiding: false,
            enablePinning: false,
            grow: false,
            enableSorting: true,
            muiTableHeadCellProps: {
                align: 'center'
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        },
        {
            accessorFn: (row: InstallmentPlanDto) => `${row?.profile?.firstName} ${row?.profile?.lastName}`,
            header: 'ชื่อ-นามสกุล',
            enableHiding: false,
            enableSorting: false,
            enablePinning: false,
            Cell: ({ renderedCellValue, row }) => (
                <Box width={160} >
                    <Typography noWrap >
                        {renderedCellValue}
                    </Typography>
                </Box>
            ),
        },
        {
            accessorFn: (row: InstallmentPlanDto) => {
                if (row?.profile?.phoneNumbers) {
                    return `${row?.profile?.phoneNumbers?.map(e => `${e.phoneNumber}`)?.join(',')}`
                } else {
                    return ''
                }
            },
            header: 'เบอร์โทร',
            enableHiding: false,
            enableSorting: false,
            enablePinning: false,
        },
        {
            accessorFn: (row: InstallmentPlanDto) => {
                if (row?.installmentTotalAmount) {
                    return <Typography>{`${currencyFormat.format(row.totalAmount || 0)}`}</Typography>
                } else {
                    return '-'
                }
            },
            header: 'ยอดรวม',
            enableHiding: false,
            enableSorting: false,
            enablePinning: false,
            size: 120,
            muiTableBodyCellProps: {
                align: 'right',
            },
        },
        {
            accessorFn: (row: InstallmentPlanDto) => {
                if (row?.downPaymentAmount) {
                    return <Typography>{`${currencyFormat.format(row.downPaymentAmount || 0)}`}</Typography>
                } else {
                    return '-'
                }
            },
            header: 'ยอดเงินดาวน์',
            enableHiding: false,
            enableSorting: false,
            enablePinning: false,
            size: 120,
            muiTableBodyCellProps: {
                align: 'right',
            },
        },
        {
            accessorFn: (row: InstallmentPlanDto) => {
                if (row?.installmentTotalAmount) {
                    return <Typography>{`${currencyFormat.format(row.installmentAmount || 0)}`}</Typography>
                } else {
                    return '-'
                }
            },
            header: 'ยอดผ่อนต่อเดือน',
            enableHiding: false,
            enableSorting: false,
            enablePinning: false,
            size: 120,
            muiTableBodyCellProps: {
                align: 'right',
            },
        },
        {
            accessorFn: (row: InstallmentPlanDto) => {
                if (row?.product?.brand && row?.product?.model) {
                    return `${row?.product?.brand} ${row?.product?.model}`
                } else if (row?.product?.brand) {
                    return `${row?.product?.brand}`
                } else if (row?.product?.model) {
                    return `${row?.product?.model}`
                } else {
                    return '-'
                }
            },
            header: 'ข้อมูลเครื่อง',
            enableHiding: false,
            enableSorting: false,
            enablePinning: false,
        },
        {
            accessorFn: (row: InstallmentPlanDto) => {
                if (row.product?.imei) {
                    return `${row.product?.imei}`
                } else {
                    return '-'
                }
            },
            header: 'อีมี่ (IMEI)',
            enableHiding: false,
            enableSorting: false,
            enablePinning: false,
        },
        {
            accessorFn: (row: InstallmentPlanDto) => {
                if (row.product?.serialNumber) {
                    return `${row.product?.serialNumber}`
                } else {
                    return '-'
                }
            },
            header: 'Serial Number',
            enableHiding: false,
            enableSorting: false,
            enablePinning: false,
        },
    ]
};

export const LoanRegistrationColumnDef: MRT_ColumnDef<InstallmentPlanDto>[] = LoanColumnDef(ColumnDefType.REGISTRATION)
export const LoanApprovalColumnDef: MRT_ColumnDef<InstallmentPlanDto>[] = LoanColumnDef(ColumnDefType.APPROVAL)
export const LoanListColumnDef: MRT_ColumnDef<InstallmentPlanDto>[] = LoanColumnDef(ColumnDefType.LOAN)
