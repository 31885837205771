/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ShopPaymentChannelCreateRequest = {
    shopId: string;
    type?: ShopPaymentChannelCreateRequest.type;
    status?: ShopPaymentChannelCreateRequest.status;
    bankCode?: ShopPaymentChannelCreateRequest.bankCode;
    accountName?: string;
    accountId?: string;
    integrationToken?: string;
};
export namespace ShopPaymentChannelCreateRequest {
    export enum type {
        CASH = 'CASH',
        PROMPT_PAY = 'PROMPT_PAY',
        PROMPT_PAY_QR = 'PROMPT_PAY_QR',
        BANK_ACCOUNT = 'BANK_ACCOUNT',
        AUTOMATIC_QR = 'AUTOMATIC_QR',
    }
    export enum status {
        ACTIVE = 'ACTIVE',
        INACTIVE = 'INACTIVE',
    }
    export enum bankCode {
        BBL = 'BBL',
        BAY = 'BAY',
        SCB = 'SCB',
        KBANK = 'KBANK',
        KTB = 'KTB',
        TTB = 'TTB',
    }
}

