import { Card, Chip, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { InstallmentPaymentDto, InstallmentTerm } from "../../client/motalvip-apis/generated";
import { CustomProgressBar } from "../common/CustomProgressBar";

type Props = {
    installmentTerm?: InstallmentTerm,
    payments?: InstallmentPaymentDto[],
    activeDueDateDiff?: number
}
export default function InstallmentTermInfoCard(props: Props) {

    const dueAmount = (props.installmentTerm?.dueAmount || 0)
    const paidDueAmount = (props.installmentTerm?.paidDueAmount || 0)
    const remainingAmount = dueAmount - paidDueAmount;
    const paidAmountPercentage = (paidDueAmount / (dueAmount || 1)) * 100;
    const currencyFormat = Intl.NumberFormat('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    return (
        <>
            {props.installmentTerm ?
                <Card sx={{ width: "100%", border: '2px solid #eeeeee' }} >
                    <Stack direction={"row"} justifyContent='space-between'>
                        <Stack direction="column" sx={{ px: 2, pt: 2 }}>
                            <Typography variant="h5" >
                                {`ปัจจุบัน งวดที่ ${props.installmentTerm?.termNumber}`}
                            </Typography>
                            <Typography >
                                เหลือที่ต้องจ่ายอีก
                            </Typography>
                            <Typography variant="h4" sx={{ color: '#0b57d0' }}>
                                ฿ {currencyFormat.format(remainingAmount)}
                            </Typography>
                        </Stack>

                    </Stack>
                    <Stack sx={{ padding: 2 }}>
                        <CustomProgressBar variant="determinate" value={paidAmountPercentage} />
                    </Stack>
                    <Stack direction={'column'} justifyContent='space-between' gap={1} sx={{ padding: 2 }}>
                        <Stack direction={{ sm: 'column', md: 'row' }} gap={1}>
                            <Stack direction={"column"} gap={1} sx={{ width: "100%", border: '2px solid #eeeeee', borderRadius: 3, padding: 2 }}>
                                <Typography>
                                    วันที่ครบกำหนด
                                </Typography>
                                <Stack direction={"row"} gap={1}>
                                    <Typography variant="h5" sx={{ color: '#0b57d0', lineHeight: '32px' }}>
                                        {dayjs(props.installmentTerm?.dueDate).format('DD/MM/YYYY') || "N/A"}
                                    </Typography>
                                    <Chip sx={{ border: '3px solid' }} variant='outlined' size='medium' color={props.activeDueDateDiff! >= 0 ? 'success' : 'error'} label={
                                        props?.activeDueDateDiff === 0
                                            ? 'ถึงกำหนด'
                                            : props.activeDueDateDiff! > 0
                                                ? `อีก ${props.activeDueDateDiff || 0} วัน`
                                                : `เกิน ${-(props.activeDueDateDiff || 0)} วัน`}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack direction={{ sm: 'column', md: 'row' }} gap={1}>
                            <Stack direction={"column"} gap={1} sx={{ width: "100%", border: '2px solid #eeeeee', borderRadius: 3, padding: 2 }} >
                                <Typography>
                                    ค่างวด
                                </Typography>
                                <Typography variant="h5" sx={{ color: '#0b57d0' }}>
                                    ฿ {`${currencyFormat.format(props.installmentTerm?.dueAmount || 0)}`}
                                </Typography>
                            </Stack>
                            <Stack direction={"column"} gap={1} sx={{ width: "100%", border: '2px solid #eeeeee', borderRadius: 3, padding: 2 }} >
                                <Typography>
                                    ค่างวดจ่ายแล้ว
                                </Typography>
                                <Typography variant="h5" sx={{ color: '#0b57d0' }}>
                                    ฿ {`${currencyFormat.format(props.installmentTerm?.paidDueAmount || 0)}`}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack direction={{ sm: 'column', md: 'row' }} gap={1}>
                            <Stack direction={"column"} gap={1} sx={{ width: "100%", border: '2px solid #eeeeee', borderRadius: 3, padding: 2 }} >
                                <Typography>
                                    ค่าปรับที่ควรเก็บ
                                </Typography>
                                <Typography variant="h5" sx={{ color: '#e70000' }}>
                                    ฿ {`${currencyFormat.format(props.installmentTerm?.fineAmount || 0)}`}
                                </Typography>
                            </Stack>
                            <Stack direction={"column"} gap={1} sx={{ width: "100%", border: '2px solid #eeeeee', borderRadius: 3, padding: 2 }} >
                                <Typography>
                                    ค่าปรับจ่ายแล้ว
                                </Typography>
                                <Typography variant="h5" sx={{ color: '#0b57d0' }}>
                                    ฿ {`${currencyFormat.format(props.installmentTerm?.paidFineAmount || 0)}`}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Card>
                :
                <Card sx={{ width: "100%", border: '2px solid #eeeeee' }} >
                    <Stack direction={"row"} justifyContent='center' alignItems='center'>
                        <Stack direction="column" sx={{ px: 2, py: 4, }}>
                            <Typography variant="h5">
                                ไม่มีงวดปัจจุบัน
                            </Typography>
                        </Stack>

                    </Stack>
                </Card>
            }
        </>
    )
}