
import { LinearProgress, linearProgressClasses, styled } from "@mui/material";

export const CustomProgressBar = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[200],
        ...theme.applyStyles('dark', {
            backgroundColor: theme.palette.grey[800],
        }),
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#0b57d0',
        ...theme.applyStyles('dark', {
            backgroundColor: '#308fe8',
        }),
    },
}));