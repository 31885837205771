import { Box, Container, Paper, Stack, Typography } from "@mui/material"
import { useShopQuery } from "../client/query-client"
import { useParams } from "react-router-dom"


type ShopUnavailableScreenProps = {
    message?: string
}

export const ShopUnavailableScreen = (props: ShopUnavailableScreenProps) => {
    const { shopId } = useParams()
    const shopQuery = useShopQuery(shopId || '')
    return (
        <Container>
            <Paper sx={{ p: 4, mt: { xs: 2, sm: 2, md: 4 } }} >
                <Stack justifyContent='center' alignItems='center' gap={2}>
                    <Typography variant="h5">ร้านค้ายังไม่พร้อมใช้งาน</Typography>
                    <Typography variant="h6">{props.message ? props.message : 'อยู่ระหว่างการรออนุมัติ'}</Typography>
                    <Box sx={{ p: 2, width: { xs: '100%', md: '50%' } }}>
                        <Stack gap={2} alignItems='center'>
                            <Typography>ชื่อร้านค้า</Typography>
                            <Typography color='primary'>{shopQuery.data?.name}</Typography>
                            <Typography>ที่อยู่</Typography>
                            <Typography color='primary'>{shopQuery.data?.address}</Typography>
                            <Typography>หมายเลขโทรศัพท์</Typography>
                            <Typography color='primary'>{shopQuery.data?.phoneNumbers.map(p => p.phoneNumber).join(',')}</Typography>
                        </Stack>
                    </Box>
                </Stack>
            </Paper>
        </Container>
    )
}