import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, Button, Card, Chip, IconButton, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import "dayjs/locale/th";
import { useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import toast from "react-hot-toast";
import { useParams, useSearchParams } from "react-router-dom";
import { InstallmentPaymentDto, InstallmentPlanDocumentDto, InstallmentPlanDto, InstallmentTerm } from "../../../client/motalvip-apis/generated";
import { useCreateInstallmentPlanDocument, useInstallmentPlanQuery } from "../../../client/query-client";
import { CustomDataField } from '../../../component/common/CustomDataField';
import { Spin } from '../../../component/common/Spin';
import { PaymentTable } from "../../../component/Installment/InstallmentPaymentTable";
import { InstallmentPlanDocumentCard } from '../../../component/Installment/InstallmentPlanDocumentCard';
import InstallmentPlanInfoCard from '../../../component/Installment/InstallmentPlanInfoCard';
import InstallmentTermInfoCard from "../../../component/Installment/InstallmentTermInfoCard";
import { InstallmentTermTable } from "../../../component/Installment/InstallmentTermTable";
import { uploadFile } from '../../../util/FileUtils';
import { InstallmentPaymentCreateDialog } from '../../../component/Installment/InstallmentPaymentCreateDialog';
import { InstallmentPlanDeleteDialog } from '../../../component/Installment/InstallmentPlanDeleteDialog';
import { InstallmentPlanNoteDialog } from '../../../component/Installment/InstallmentPlanNoteDialog';

interface FileWithPreview {
    file: InstallmentPlanDocumentDto;
    uploaded: boolean;
}
interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <Box
            sx={{ maxWidth: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </Box>
    );
}

type Props = {
};
export const InstallmentPlanDetailScreen = (props: Props) => {

    const currencyFormat = Intl.NumberFormat('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    const { shopId, installmentPlanId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedTab, setSelectedTab] = useState<number>(Number(searchParams.get("tab")) || 0)

    const installmentPlanQuery = useInstallmentPlanQuery(installmentPlanId || '');
    const createInstallmentPlanDocumentMutation = useCreateInstallmentPlanDocument();

    const [documents, setDocuments] = useState<FileWithPreview[]>([]);
    const [activeInstallmentTerm, setActiveInstallmentTerm] = useState<InstallmentTerm>()

    const [installmentTerms, setInstallmentTerms] = useState<InstallmentTerm[]>()

    // Payment Dialog
    const MAX = 999999999
    const [openPaymentDialog, setOpenPaymentDialog] = useState<boolean>(false)
    const [dialogTitle, setDialogTitle] = useState<string>()
    const [defaultInstallmentPaymentDto, setDefaultInstallmentPaymentDto] = useState<InstallmentPaymentDto>();
    const [maxDueAmount, setMaxDueAmount] = useState<number>(MAX)
    const [maxFineAmount, setMaxFineAmount] = useState<number>(MAX)

    // Installment Delete Dialog
    const [openInstallmentPlanDeleteDialog, setOpenInstallmentPlanDeleteDialog] = useState<boolean>(false)

    // Installment Plan Note Dialog
    const [openInstallmentPlanNoteDialog, setOpenInstallmentPlanNoteDialog] = useState<boolean>(false)

    useEffect(() => {
        setInstallmentTerms(installmentPlanQuery.data?.installmentTerms?.sort((a, b) => { return (a.termNumber || 0) - (b.termNumber || 0) }))
    }, [installmentPlanQuery.data?.installmentTerms])
    useEffect(() => {
        setActiveInstallmentTerm(installmentPlanQuery.data?.installmentTerms?.find(i => i.termNumber === installmentPlanQuery.data.activeTermNumber))
    }, [installmentPlanQuery.data?.installmentTerms, installmentPlanQuery.data?.activeTermNumber])
    useEffect(() => {
        setDocuments(installmentPlanQuery.data?.documents?.map(doc => ({ file: doc, uploaded: true })) || [])
    }, [installmentPlanQuery.data?.documents])
    useEffect(() => {
        if (selectedTab !== Number(searchParams.get("tab"))) {
            const searchParams = new URLSearchParams();
            searchParams.set('tab', selectedTab.toString());
            setSearchParams(searchParams, { replace: true });
        }
    }, [selectedTab, searchParams, setSearchParams]);

    const onDrop = (files: File[]) => {
        files.forEach(file => {
            createInstallmentPlanDocumentMutation.mutate({
                originalName: file.name,
                type: InstallmentPlanDocumentDto.type.OTHERS,
                installmentPlanId: installmentPlanId || ''
            }, {
                onSuccess: async (res) => {
                    if (res.uploadUrl) {
                        setDocuments((prev) => [...prev, { file: res, uploaded: false }])
                        uploadFile(file, res.uploadUrl, () => {
                            setDocuments((prev) =>
                                prev.map((f) =>
                                    f.file.originalName === file.name ? { ...f, uploaded: true } : f
                                )
                            )
                        })
                    }
                },
                onError: (error) => {
                }
            })
        })
    }
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            <Spin spinning={installmentPlanQuery.isLoading}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"th"}>
                    <Box p={2} mb={16} maxWidth='100vw'>
                        <Box sx={{ justifyContent: 'space-between' }}>
                            <Stack direction='column'>
                                <Typography variant="h5" sx={{ pb: 2 }} >{`รายการติดตามเคส ลำดับ ${installmentPlanQuery.data?.sequenceNumber}`}</Typography>
                            </Stack>
                        </Box>

                        <Paper elevation={2} sx={{ backgroundColor: 'white' }}>
                            <Tabs
                                variant="scrollable"
                                value={selectedTab} onChange={onTabChange}
                                scrollButtons
                                allowScrollButtonsMobile
                            >
                                <Tab label='ข้อมูลสินเชื่อ' />
                                <Tab label='ตารางงวดการผ่อน' />
                                <Tab label='ประวัติการจ่ายเงิน' />
                                <Tab label='ไฟล์แนบ' />
                            </Tabs>
                        </Paper>

                        <TabPanel value={selectedTab} index={0}>
                            <Box sx={{ bgcolor: "white", width: '100%', p: 2 }}>
                                <Stack direction={{ md: 'column', lg: 'row' }} gap={2}>
                                    <Card sx={{ width: "100%", border: '2px solid #eeeeee', p: 2 }} >
                                        <Stack direction='row' justifyContent='space-between'>
                                            <Stack>
                                                <Typography variant="h5" >
                                                    ลูกค้า
                                                </Typography>
                                                <Stack gap={2}>
                                                    <Typography variant='h4' sx={{ color: '#0b57d0' }}>{`${installmentPlanQuery.data?.profile?.firstName} ${installmentPlanQuery.data?.profile?.lastName}`}</Typography>
                                                    <Stack>
                                                        <Typography>{`เบอร์โทรศัพท์: ${installmentPlanQuery.data?.profile?.phoneNumbers?.map(e => e.phoneNumber).join(", ") || '-'}`}</Typography>
                                                        <Typography>{`หน้า Facebook: ${installmentPlanQuery.data?.profile?.facebookUrl || '-'}`}</Typography>
                                                        <Typography>{`ไอดี LINE ${installmentPlanQuery.data?.profile?.lineId || '-'}`}</Typography>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                            {installmentPlanQuery.data?.activeInstallmentPaymentDate &&
                                                <Stack direction='column' alignItems='center' gap={1}>
                                                    <Chip label={'นัดจ่าย'} sx={{ width: 180, border: '3px solid #9500ba', backgroundColor: 'white', color: '#9500ba' }} />
                                                    <Box sx={{ width: 180, py: 1, px: 2, border: '1px solid #dddddd', borderRadius: 2 }}>
                                                        <Stack direction={'row'} gap={1}>
                                                            <Typography>วันที่</Typography>
                                                            <Typography color='secondary'>{`${dayjs(installmentPlanQuery.data?.activeInstallmentPaymentDate).format('YYYY-MM-DD')}`}</Typography>
                                                        </Stack>
                                                        <Stack direction={'row'} gap={1}>
                                                            <Typography>ยอด</Typography>
                                                            <Typography color='secondary'>{`${currencyFormat.format(installmentPlanQuery.data?.activeInstallmentPaymentAmount || 0)}`}</Typography>
                                                        </Stack>
                                                    </Box>
                                                </Stack>
                                            }
                                        </Stack>
                                    </Card>
                                    <Card sx={{ width: "100%", border: '2px solid #eeeeee', p: 2 }} >
                                        <Stack direction={'column'} gap={1}>
                                            <Stack direction={{ md: 'column', lg: 'row' }} gap={1}>
                                                <Button fullWidth variant="contained"
                                                    color="primary"
                                                    sx={{ alignSelf: 'center', height: '56px' }}
                                                    onClick={() => {
                                                        const totalTermPaidAmount = installmentPlanQuery.data?.installmentTerms?.reduce((sum, i) => sum + (i.paidDueAmount || 0), 0) || 0
                                                        const totalPaidAmount = totalTermPaidAmount + (installmentPlanQuery.data?.downPaymentAmount || 0);
                                                        const totalAmount = (installmentPlanQuery.data?.totalAmount || 0)
                                                        const remainingAmount = totalAmount - totalPaidAmount;
                                                        setDialogTitle('ปิดยอดสินเชื่อ')
                                                        setDefaultInstallmentPaymentDto({
                                                            shopId: shopId,
                                                            installmentPlanId: installmentPlanId,
                                                            appointmentAt: dayjs().format("YYYY-MM-DD"),
                                                            transactionAt: dayjs().format("YYYY-MM-DD"),
                                                            status: InstallmentPaymentDto.status.COMPLETED,
                                                            type: InstallmentPaymentDto.type.REPAYMENT_FULL,
                                                            paidDueAmount: 0,
                                                            paidFineAmount: 0,
                                                            installmentTermNumber: installmentPlanQuery.data?.activeTermNumber,
                                                            shopPaymentChannelId: "",
                                                            documents: []
                                                        })
                                                        setMaxDueAmount(remainingAmount)
                                                        setMaxFineAmount(MAX)
                                                        setOpenPaymentDialog(true)
                                                    }}>ปิดยอด</Button>
                                                <Button fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ alignSelf: 'center', height: '56px' }}
                                                    onClick={() => {
                                                        setDialogTitle('จ่ายค่างวด')
                                                        setDefaultInstallmentPaymentDto({
                                                            shopId: shopId,
                                                            installmentPlanId: installmentPlanId,
                                                            appointmentAt: dayjs().format("YYYY-MM-DD"),
                                                            transactionAt: dayjs().format("YYYY-MM-DD"),
                                                            status: InstallmentPaymentDto.status.COMPLETED,
                                                            type: InstallmentPaymentDto.type.TERM_PAYMENT,
                                                            paidDueAmount: 0,
                                                            paidFineAmount: 0,
                                                            installmentTermNumber: installmentPlanQuery.data?.activeTermNumber,
                                                            shopPaymentChannelId: "",
                                                            documents: []
                                                        })
                                                        setMaxDueAmount(activeInstallmentTerm?.dueAmount || MAX)
                                                        setMaxFineAmount(MAX)
                                                        setOpenPaymentDialog(true)
                                                    }}>จ่ายค่างวด</Button>
                                                <Button fullWidth
                                                    variant="outlined"
                                                    color="primary"
                                                    sx={{ alignSelf: 'center', height: '56px' }}
                                                    onClick={() => {
                                                        setDialogTitle('นัดจ่าย')
                                                        setDefaultInstallmentPaymentDto({
                                                            shopId: shopId,
                                                            installmentPlanId: installmentPlanId,
                                                            appointmentAt: dayjs().format("YYYY-MM-DD"),
                                                            transactionAt: dayjs().format("YYYY-MM-DD"),
                                                            status: InstallmentPaymentDto.status.COMPLETED,
                                                            type: InstallmentPaymentDto.type.APPOINTMENT,
                                                            paidDueAmount: 0,
                                                            paidFineAmount: 0,
                                                            installmentTermNumber: installmentPlanQuery.data?.activeTermNumber,
                                                            shopPaymentChannelId: "",
                                                            documents: []
                                                        })
                                                        setMaxDueAmount(activeInstallmentTerm?.dueAmount || MAX)
                                                        setMaxFineAmount(MAX)
                                                        setOpenPaymentDialog(true)
                                                    }}>นัดจ่าย</Button>
                                            </Stack>
                                            <Stack direction={{ md: 'column', lg: 'row' }} gap={1}>
                                                <Button fullWidth
                                                    variant="outlined"
                                                    color="primary"
                                                    sx={{ alignSelf: 'center', height: '56px' }}
                                                    onClick={() => {
                                                        setOpenInstallmentPlanNoteDialog(true)
                                                    }}>แก้ไขโน้ต</Button>
                                                {/* <Button fullWidth
                                                    variant="outlined"
                                                    color="error"
                                                    sx={{ alignSelf: 'center', height: '56px' }}
                                                    onClick={() => {
                                                        setOpenInstallmentPlanDeleteDialog(true)
                                                    }}>ลบ</Button> */}
                                                <Box width={'100%'} p={2}></Box>
                                                <Box width={'100%'} p={2}></Box>
                                            </Stack>
                                        </Stack>
                                    </Card>
                                </Stack>
                            </Box>

                            <Box sx={{ bgcolor: "white", width: '100%', px: 2 }}>
                                <Stack direction={{ md: 'column', lg: 'row' }} sx={{ mb: 2 }} gap={2}>
                                    <InstallmentPlanInfoCard installmentPlan={installmentPlanQuery.data} />
                                    <InstallmentTermInfoCard
                                        installmentTerm={activeInstallmentTerm}
                                        payments={installmentPlanQuery.data?.payments?.filter(p => p.installmentTermNumber === installmentPlanQuery.data.activeTermNumber)}
                                        activeDueDateDiff={installmentPlanQuery.data?.activeDueDateDiff}
                                    />
                                </Stack>
                            </Box>

                            <Box sx={{ bgcolor: "white", width: '100%', p: 2 }}>
                                <Stack direction={{ md: 'column', lg: 'row' }} gap={2}>
                                    <Card sx={{ width: "100%", border: '2px solid #eeeeee', py: 2 }} >
                                        <Stack sx={{ px: 2 }} gap={2}>
                                            <Typography variant="h5" >
                                                ข้อมูลอื่นๆ
                                            </Typography>
                                            <Stack>
                                                <CustomDataField label={'ลิ้งสำหรับลูกค้า'} >
                                                    <Stack direction={'row'} gap={1}>
                                                        <Typography>{installmentPlanId ? `${process.env.REACT_APP_MOTAL_CUSTOMER_WEB_URL}?loanId=${installmentPlanId}` : '-'}</Typography>
                                                        <IconButton size="small" onClick={() => {
                                                            navigator.clipboard.writeText(`${process.env.REACT_APP_MOTAL_CUSTOMER_WEB_URL}?loanId=${installmentPlanId}`)
                                                                .then(() => {
                                                                    toast.success('คัดลอกสำเร็จ');
                                                                })
                                                        }}>
                                                            <ContentCopyIcon fontSize='inherit' />
                                                        </IconButton>
                                                    </Stack>
                                                </CustomDataField>
                                                <CustomDataField label='วันที่สมัคร'><Typography>{installmentPlanQuery.data?.registrationDate ? `${dayjs(installmentPlanQuery.data.registrationDate).locale("th").format('DD MMM YYYY')}` : '-'}</Typography></CustomDataField>
                                                <CustomDataField label='รหัสอ้างอิง'><Typography>{installmentPlanQuery.data?.referenceId || '-'}</Typography></CustomDataField>
                                                <CustomDataField label='ยอดรวม'><Typography>{`${currencyFormat.format(installmentPlanQuery.data?.totalAmount || 0)}`}</Typography></CustomDataField>
                                                <CustomDataField label='ยอดเงินดาวน์'><Typography>{`${currencyFormat.format(installmentPlanQuery.data?.downPaymentAmount || 0)}`}</Typography></CustomDataField>
                                                <CustomDataField label='วันที่จ่ายเงินดาวน์'><Typography>{installmentPlanQuery.data?.downPaymentDate ? `${dayjs(installmentPlanQuery.data.downPaymentDate).locale("th").format('DD MMM YYYY')}` : '-'}</Typography></CustomDataField>
                                                <CustomDataField label='ยอดผ่อนต่อเดือน'><Typography>{`${currencyFormat.format(installmentPlanQuery.data?.installmentAmount || 0)}`}</Typography></CustomDataField>
                                                <CustomDataField label='จำนวนงวด'><Typography>{`${installmentPlanQuery.data?.installmentCount || 0} งวด (ทุก ${installmentPlanQuery.data?.installmentFrequency} ${installmentPlanQuery.data?.installmentFrequencyType === InstallmentPlanDto.installmentFrequencyType.MONTH ? 'เดือน' : 'วัน'})`}</Typography></CustomDataField>
                                                <CustomDataField label='อีเมล'><Typography>{installmentPlanQuery.data?.profile?.email || '-'}</Typography></CustomDataField>
                                                <CustomDataField label='เลขบัตรประชาชน'><Typography>{installmentPlanQuery.data?.profile?.citizenId || '-'}</Typography></CustomDataField>
                                                <CustomDataField label='เลขพาสปอร์ต'><Typography>{installmentPlanQuery.data?.profile?.passportNumber || '-'}</Typography></CustomDataField>
                                                <CustomDataField label='ที่อยู่ปัจจุบัน'><Typography>{installmentPlanQuery.data?.profile?.currentAddress || '-'}</Typography></CustomDataField>
                                                <CustomDataField label='ที่อยู่ตามบัตร'><Typography>{installmentPlanQuery.data?.profile?.citizenAddress || '-'}</Typography></CustomDataField>
                                                <CustomDataField label='อาชีพ'><Typography>{installmentPlanQuery.data?.profile?.occupation || '-'}</Typography></CustomDataField>
                                                <CustomDataField label='สถานที่ทำงาน'><Typography>{installmentPlanQuery.data?.profile?.workPlace || '-'}</Typography></CustomDataField>
                                            </Stack>
                                        </Stack>
                                    </Card>
                                    <Card sx={{ width: "100%", border: '2px solid #eeeeee' }} >
                                        <Box p={2}>
                                            <Stack gap={2}>
                                                <Typography variant="h5" >
                                                    โน้ต
                                                </Typography>
                                                <Box p={2} sx={{ border: '2px solid #eeeeee', borderRadius: 2 }}>
                                                    <Typography sx={{ whiteSpace: 'pre-line' }}>
                                                        {installmentPlanQuery.data?.note || '-'}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </Card>
                                </Stack>
                            </Box>
                        </TabPanel>
                        <TabPanel value={selectedTab} index={1}>
                            <Paper elevation={2} sx={{ p: 2, backgroundColor: 'white', maxWidth: '100%' }}>
                                <InstallmentTermTable
                                    payment={installmentPlanQuery.data?.payments}
                                    data={installmentTerms}
                                    showPayment={true}
                                />
                            </Paper>
                        </TabPanel>
                        <TabPanel value={selectedTab} index={2}>
                            <Paper elevation={2} sx={{ p: 2, backgroundColor: 'white', maxWidth: '100%' }}>
                                <PaymentTable data={installmentPlanQuery.data?.payments} lastestPaymentId={installmentPlanQuery.data?.activeInstallmentPaymentId} />
                            </Paper>
                        </TabPanel>
                        <TabPanel value={selectedTab} index={3}>
                            <Box sx={{ p: 2, bgcolor: 'white' }}>
                                <Stack gap={2}>
                                    <Box
                                        {...getRootProps()}
                                        sx={{
                                            width: '100%',
                                            border: '2px dashed darkgray',
                                            borderRadius: '8px',
                                            backgroundColor: 'white',
                                            padding: 4,
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: '#b3e5fc',
                                                color: '#03a9f4',
                                                borderColor: '#03a9f4',
                                            },
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '120px'
                                        }}
                                    >
                                        <input {...getInputProps()} />
                                        <Stack direction='column' alignItems='center' gap={1}>
                                            <FileUploadIcon />
                                            <Typography>อัพโหลดไฟล์</Typography>
                                        </Stack>
                                    </Box>
                                    <Box sx={{ width: '100%', }}>
                                        <Stack sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }} direction={'row'} gap={2}>
                                            {documents.map(doc =>
                                                <InstallmentPlanDocumentCard
                                                    document={doc.file}
                                                    uploaded={doc.uploaded}
                                                    onDelete={() => { }}
                                                    onDownload={() => { }} />
                                            )}</Stack>
                                    </Box>
                                </Stack>
                            </Box>
                        </TabPanel>
                    </Box >
                    <InstallmentPaymentCreateDialog
                        open={openPaymentDialog}
                        onClose={() => setOpenPaymentDialog(false)}
                        title={dialogTitle}
                        defauleValues={defaultInstallmentPaymentDto}
                        maxDueAmount={maxDueAmount}
                        maxFineAmount={maxFineAmount}
                    />
                    <InstallmentPlanDeleteDialog
                        open={openInstallmentPlanDeleteDialog}
                        onClose={() => setOpenInstallmentPlanDeleteDialog(false)}
                        installmentPlanDto={installmentPlanQuery.data}
                    />
                    <InstallmentPlanNoteDialog
                        open={openInstallmentPlanNoteDialog}
                        onClose={() => setOpenInstallmentPlanNoteDialog(false)}
                        installmentPlanDto={installmentPlanQuery.data}
                    />
                </LocalizationProvider >
            </Spin >
        </>
    );
};
