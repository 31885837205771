/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type LoanDocumentUploadConfirmRequest = {
    loanId?: string;
    objectKey?: string;
    type?: LoanDocumentUploadConfirmRequest.type;
};
export namespace LoanDocumentUploadConfirmRequest {
    export enum type {
        CITIZEN_ID_CARD = 'CITIZEN_ID_CARD',
        FACEBOOK_SCREEN = 'FACEBOOK_SCREEN',
        PHOTO_WITH_CARD = 'PHOTO_WITH_CARD',
        CONTRACT = 'CONTRACT',
        GENERATED_CONTRACT = 'GENERATED_CONTRACT',
        IMEI_OR_SERIAL = 'IMEI_OR_SERIAL',
        DEVICE_IMAGE = 'DEVICE_IMAGE',
        OTHERS = 'OTHERS',
    }
}

