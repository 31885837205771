/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type InstallmentHistoryDto = {
    installmentPlanId?: string;
    installmentPlanStatus?: InstallmentHistoryDto.installmentPlanStatus;
    installmentPlanActiveTermNumber?: number;
    installmentPlanInstallmentCount?: number;
    shopName?: string;
    shopPhoneNumber?: string;
    productImei?: string;
    productSerialNumber?: string;
};
export namespace InstallmentHistoryDto {
    export enum installmentPlanStatus {
        DRAFT = 'DRAFT',
        PENDING_CONTRACT_DOCUMENTS = 'PENDING_CONTRACT_DOCUMENTS',
        PENDING_CONFIRM_DOWN_PAYMENT = 'PENDING_CONFIRM_DOWN_PAYMENT',
        NORMAL = 'NORMAL',
        CLOSED = 'CLOSED',
        REFUND = 'REFUND',
        FOLLOWING_UP = 'FOLLOWING_UP',
        CANNOT_CONTACT_CUSTOMER = 'CANNOT_CONTACT_CUSTOMER',
        NON_PERFORMING = 'NON_PERFORMING',
        REPORTED = 'REPORTED',
        CANCEL = 'CANCEL',
    }
}

