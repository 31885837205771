/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type StepUpdateLoanDraftDeviceAndLoanRequest = {
    loanId?: string;
    shopId?: string;
    usedType?: StepUpdateLoanDraftDeviceAndLoanRequest.usedType;
    brand?: string;
    model?: string;
    color?: string;
    description?: string;
    installmentPlanFundSource?: StepUpdateLoanDraftDeviceAndLoanRequest.installmentPlanFundSource;
    downPaymentAmount?: number;
    downPaymentDate?: string;
    installmentCount?: number;
    installmentAmount?: number;
    installmentStartDate?: string;
    installmentFrequency?: number;
    installmentFrequencyType?: StepUpdateLoanDraftDeviceAndLoanRequest.installmentFrequencyType;
    deviceImei?: string;
    deviceSerialNumber?: string;
};
export namespace StepUpdateLoanDraftDeviceAndLoanRequest {
    export enum usedType {
        NEW = 'NEW',
        USED = 'USED',
    }
    export enum installmentPlanFundSource {
        SHOP = 'SHOP',
        FINANCE = 'FINANCE',
    }
    export enum installmentFrequencyType {
        DAY = 'DAY',
        MONTH = 'MONTH',
    }
}

