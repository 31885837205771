/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type User = {
    id?: string;
    createdAt?: string;
    updatedAt?: string;
    mongoId?: string;
    clerkUserId?: string;
    primaryEmail?: string;
    firstName?: string;
    lastName?: string;
    status?: User.status;
};
export namespace User {
    export enum status {
        PENDING = 'PENDING',
        ACTIVE = 'ACTIVE',
        SUSPENDED = 'SUSPENDED',
    }
}

