/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type InstallmentPaymentDocumentDto = {
    id?: string;
    originalName: string;
    storedName?: string;
    fileExtension?: string;
    objectKey?: string;
    installmentPaymentId?: string;
    type: InstallmentPaymentDocumentDto.type;
    downloadUrl?: string;
    uploadUrl?: string;
};
export namespace InstallmentPaymentDocumentDto {
    export enum type {
        PAYSLIP = 'PAYSLIP',
    }
}

