import { useEffect, useState } from "react"
import { InstallmentPaymentDto } from "../../client/motalvip-apis/generated"
import DropdownChip from "../DropDownChip"

type PaymentTypeChipProps = {
    type: InstallmentPaymentDto.type
    selectable?: boolean
    onSelect?: (option: InstallmentPaymentDto.type) => void;
    disabled?: boolean;
}

export const PaymentTypeChip: React.FC<PaymentTypeChipProps> = ({ disabled, type, selectable = false, onSelect = () => { } }) => {

    const [selectedOption, setSelectedOption] = useState<InstallmentPaymentDto.type>(type);

    useEffect(() => {
        setSelectedOption(type)
    }, [type])

    const handleSelect = (option: InstallmentPaymentDto.type) => {
        setSelectedOption(option);
        onSelect(option);
    }

    const options = [
        { value: InstallmentPaymentDto.type.DOWN_PAYMENT, display: 'เงินดาวน์' },
        { value: InstallmentPaymentDto.type.REPAYMENT_FULL, display: 'ปิดค่างวด' },
        { value: InstallmentPaymentDto.type.TERM_PAYMENT, display: 'ค่างวด' },
        { value: InstallmentPaymentDto.type.REPAYMENT_PARTIAL, display: 'แบ่งจ่ายค่างวด' },
        { value: InstallmentPaymentDto.type.APPOINTMENT, display: 'นัดจ่าย' },
        { value: InstallmentPaymentDto.type.CLOSED, display: 'ปิดยอดทั้งหมด' },
        { value: InstallmentPaymentDto.type.FINE, display: 'ค่าปรับ' },
    ];

    const colorMapping = {
        'เงินดาวน์': '#0b57d0',
        'นัดจ่าย': '#9500ba',
        'ค่างวด': '#43a047',
        'แบ่งจ่ายค่างวด': '#bbdefb',
        'ปิดยอดทั้งหมด': '#aed581',
        'ค่าปรับ': '#ef9a9a',
    };

    return (
        <DropdownChip
            disabled={disabled}
            label={options.find(o => o.value === selectedOption)!.display}
            options={options}
            onSelect={handleSelect}
            colorMapping={colorMapping}
            selectable={selectable}
            variant="outlined"
        />
    )

}