import { Box, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { MaterialReactTable, MRT_ColumnDef, MRT_SortingState, useMaterialReactTable } from "material-react-table";
import { useMemo, useState } from "react";
// import { LoanResponse } from "../../client/motal-apis/generated";
import { useShopContext } from "../../context/ShopContextProvider";
import { LoanStatusChip } from "../LoanStatusChip";
import { InstallmentPlanDto } from "../../client/motalvip-apis/generated";


type Props = {
    data: InstallmentPlanDto[] | undefined
}

export const CustomerHistory = (props: Props) => {
    const currencyFormat = Intl.NumberFormat('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'sequenceNumber', desc: false }]);
    const shopCtx = useShopContext();
    const columns = useMemo<MRT_ColumnDef<InstallmentPlanDto>[]>(
        () => [
            {
                id: 'date',
                accessorFn: (row: InstallmentPlanDto) => {
                    return <Typography>{dayjs(row.downPaymentDate || row.registrationDate).locale("th").format('DD MMM YYYY')}</Typography>
                }, //simple recommended way to define a column
                header: 'วันที่สินเชื่อ',
                enableHiding: false,
                enablePinning: false,
                enableSorting: false,
                size: 100,
                Cell: ({ renderedCellValue, row }) => (
                    <Box width={100}>
                        <Typography noWrap >
                            {renderedCellValue}
                        </Typography>
                    </Box>
                ),
            },
            // {
            //     id: 'shopName',
            //     accessorFn: (row: LoanResponse) => {
            //         return <Typography>{row.shop?.shopName}</Typography>
            //     }, //simple recommended way to define a column
            //     header: 'ร้านค้า',
            //     enableHiding: false,
            //     enablePinning: false,
            //     enableSorting: false,
            //     Cell: ({ renderedCellValue, row }) => (
            //         <Box >
            //             <Typography noWrap >
            //                 {renderedCellValue}
            //             </Typography>
            //         </Box>
            //     ),
            // },
            {
                id: 'referenceId',
                accessorFn: (row: InstallmentPlanDto) => <Typography >{row.referenceId}</Typography>, //simple recommended way to define a column
                header: 'หมายเลขเคส',
                enableHiding: false,
                enablePinning: false,
                grow: false,
                enableSorting: false,
            },
            {
                id: 'status',
                accessorFn: (row: InstallmentPlanDto) => <LoanStatusChip status={(row.status)} />, //simple recommended way to define a column
                header: 'สถานะสินเชื่อ',
                enableHiding: false,
                enableSorting: false,
                enablePinning: false,
                muiTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                id: 'activeTermNumber',
                accessorFn: (row: InstallmentPlanDto) => <Typography >{`${row.activeTermNumber ? row.activeTermNumber : "-"}`}</Typography>, //simple recommended way to define a column
                header: 'งวดปัจจุบัน',
                enableHiding: false,
                enablePinning: false,
                enableSorting: false,
                size: 80,
                muiTableHeadCellProps: {
                    align: 'center'
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                accessorFn: (row: InstallmentPlanDto) => {
                    if (row.installmentTotalAmount) {
                        return <Typography>{`${currencyFormat.format(row.totalAmount || 0)}`}</Typography>
                    } else {
                        return '-'
                    }
                },
                header: 'ยอดรวม',
                enableHiding: false,
                enableSorting: false,
                enablePinning: false,
                size: 120,
                muiTableBodyCellProps: {
                    align: 'right',
                },
            },
            {
                accessorFn: (row: InstallmentPlanDto) => {
                    if (row.downPaymentAmount) {
                        return <Typography>{`${currencyFormat.format(row.downPaymentAmount || 0)}`}</Typography>
                    } else {
                        return '-'
                    }
                },
                header: 'ยอดเงินดาวน์',
                enableHiding: false,
                enableSorting: false,
                enablePinning: false,
                size: 120,
                muiTableBodyCellProps: {
                    align: 'right',
                },
            },
            {
                accessorFn: (row: InstallmentPlanDto) => {
                    if (row?.installmentTotalAmount) {
                        return <Typography>{`${currencyFormat.format(row.installmentAmount || 0)}`}</Typography>
                    } else {
                        return '-'
                    }
                },
                header: 'ยอดผ่อนต่อเดือน',
                enableHiding: false,
                enableSorting: false,
                enablePinning: false,
                size: 120,
                muiTableBodyCellProps: {
                    align: 'right',
                },
            },
            {
                accessorFn: (row: InstallmentPlanDto) => {
                    if (row?.product?.brand && row?.product?.model) {
                        return `${row?.product?.brand} ${row?.product?.model}`
                    } else if (row?.product?.brand) {
                        return `${row?.product?.brand}`
                    } else if (row?.product?.model) {
                        return `${row?.product?.model}`
                    } else {
                        return '-'
                    }
                },
                header: 'ข้อมูลเครื่อง',
                enableHiding: false,
                enableSorting: false,
                enablePinning: false,
            },
        ],
        [currencyFormat],
    );

    const table = useMaterialReactTable({
        columns: columns,
        data: props.data || [],
        layoutMode: 'semantic',
        enablePagination: false,
        enableColumnPinning: true,
        enableGlobalFilter: false,
        enableColumnFilters: false,
        enableSorting: true,
        enableHiding: false,
        enableColumnActions: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableColumnVirtualization: false,
        enableColumnOrdering: false,
        enableBottomToolbar: false,
        positionGlobalFilter: 'left',
        enableTopToolbar: false,
        manualSorting: true,
        paginationDisplayMode: 'pages',
        onSortingChange: setSorting,
        localization: {
            rowsPerPage: "จำนวนต่อหน้า"
        },
        manualPagination: true,
        initialState: {
            showGlobalFilter: false,
            columnPinning: {
                right: ['mrt-row-actions'],
            },
        },
        state: {
            sorting,
            columnVisibility: { 'totalProfit': shopCtx.isOwner },
        },
        enableRowActions: false,
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: '', //change header text
                size: 12, //make actions column wider
            },
        },
        muiTableHeadCellProps: {
            sx: {
                borderRight: '1px solid #e0e0e0', //add a border between columns
                backgroundColor: '#455a64',
                color: 'white',
                fontWeight: '500'
            },
        },
        muiTableBodyProps: {
            sx: {
                //stripe the rows, make odd rows a darker color
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td': {
                    backgroundColor: 'grey.100',
                },
            },
        },
        muiTableBodyCellProps: {
            sx: {
                borderRight: '1px solid #eeeeee', //add a border between columns
            },
        },
    });
    return (
        <>
            {props.data && props.data.length > 0 ?
                <MaterialReactTable table={table} />
                :
                <Stack width='100%' my={4} justifyContent='center' alignItems='center'>
                    <Typography>ยังไม่มีประวัติ</Typography>
                </Stack>
            }
        </>
    )
}