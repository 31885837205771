import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { usePaymentDeleteMutation } from "../../client/query-client";
import { useParams } from "react-router-dom";
import { InstallmentPaymentDto } from "../../client/motalvip-apis/generated";
import toast from "react-hot-toast";

type InstallmentPaymentUpdateDialogProps = {
    open: boolean,
    onClose: () => void,
    installmentPaymentDto: InstallmentPaymentDto,
};

export const InstallmentPaymentDeleteDialog = (props: InstallmentPaymentUpdateDialogProps) => {

    const { installmentPlanId } = useParams()
    const paymentDeleteMutation = usePaymentDeleteMutation(installmentPlanId || '')

    const onDelete = () => {
        paymentDeleteMutation.mutate({ payment: props.installmentPaymentDto }, {
            onSuccess: () => {
                toast.success('ลบข้อมูลสำเร็จ')
                props.onClose()
            },
            onError: () => {
                toast.error('เกิดข้อผิดพลาด')
                props.onClose()
            }
        })
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} >
            <DialogTitle>ยืนยันลบการจ่าย</DialogTitle>
            <DialogContent>
                ต้องการลบใช่หรือไม่?
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>ยกเลิก</Button>
                <Button color='error' onClick={() => onDelete()}>ลบ</Button>
            </DialogActions>
        </Dialog>
    )
};