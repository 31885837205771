/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InstallmentPlanDto } from '../models/InstallmentPlanDto';
import type { LoanRegistrationCreateRequest } from '../models/LoanRegistrationCreateRequest';
import type { Profile } from '../models/Profile';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LoanRegistrationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public createLoanRegistration({
        requestBody,
    }: {
        requestBody: LoanRegistrationCreateRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/loans/registrations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns Profile OK
     * @throws ApiError
     */
    public getCustomerByIdentifier({
        identifier,
    }: {
        identifier: string,
    }): CancelablePromise<Profile> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/loans/registrations/customers/{identifier}',
            path: {
                'identifier': identifier,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
