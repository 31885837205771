/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ProductDto = {
    id?: string;
    usedType?: ProductDto.usedType;
    brand?: string;
    model?: string;
    color?: string;
    description?: string;
    imei?: string;
    serialNumber?: string;
    purchaseCost?: number;
};
export namespace ProductDto {
    export enum usedType {
        NEW = 'NEW',
        USED = 'USED',
    }
}

