import { useAuth } from "@clerk/clerk-react";
import { Navigate } from "react-router-dom";
interface ProtectedRouteProps {
    element: React.ReactElement;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
    const { isLoaded, isSignedIn } = useAuth();

    if (!isLoaded) {
        return <p>Loading...</p>;
    }
    return isSignedIn ? element : <Navigate to={`/sign-in`} />;
};