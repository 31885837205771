/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreationTokenDto } from '../models/CreationTokenDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TokenService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns CreationTokenDto OK
     * @throws ApiError
     */
    public generateCreationToken(): CancelablePromise<CreationTokenDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/tokens/creation-token',
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
