import { Box, Card, CardContent, Divider, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { MaterialReactTable, MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { InstallmentPlanMonthlySummary } from "../../client/motalvip-apis/generated";
import { useShopDashboardQuery } from "../../client/query-client";


export const ShopDashboardScreen = () => {
    const { shopId } = useParams()
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [selectedMonth, setSelectedMonth] = useState<number>(dayjs().get('month') + 1)
    const currencyFormat = Intl.NumberFormat('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const shopDashboardQuery = useShopDashboardQuery(shopId || '', { year: selectedYear, month: selectedMonth })

    const handleYearChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedYear(event.target.value as number);
        shopDashboardQuery.refetch()
    }

    const installmentPlanCountColumns = useMemo<MRT_ColumnDef<InstallmentPlanMonthlySummary>[]>(
        () => [
            {
                id: 'month',
                accessorFn: (row: InstallmentPlanMonthlySummary) => {
                    return <Typography>{row.month}</Typography>
                },
                header: 'เดือน',
                enableHiding: false,
                enableSorting: false,
                grow: false,
                size: 40,
                enableResizing: false,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                Header: ({ column }) => (
                    <Box width={40}>
                        <Typography color='black'>{column.columnDef.header}</Typography>
                    </Box>
                ),
            },
            {
                id: 'status',
                header: 'จำนวนสินเชื่อ',
                Header: ({ column }) => (
                    <Typography p={1} color='white'>{column.columnDef.header}</Typography>
                ),
                columns: [
                    {
                        id: 'totalInstallmentPlanStatusNormal',
                        accessorFn: (row: InstallmentPlanMonthlySummary) =>
                            <Stack direction={'row'} gap={1} justifyContent='flex-end'>
                                <Typography>
                                    {row.totalInstallmentPlanStatusNormal}
                                </Typography>
                                <Typography color='#aaaaaa'>
                                    {`(${((row.totalInstallmentPlanStatusNormal || 0) * 100 / (row.totalInstallmentPlan || 1)).toFixed(1)}%)`}
                                </Typography>
                            </Stack>,
                        header: 'กำลังผ่อน',
                        enableHiding: false,
                        enablePinning: false,
                        grow: false,
                        enableSorting: false,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                        size: 120,

                    },
                    {
                        id: 'totalInstallmentPlanStatusClosed',
                        accessorFn: (row: InstallmentPlanMonthlySummary) =>
                            <Stack direction={'row'} gap={1} justifyContent='flex-end'>
                                <Typography>
                                    {row.totalInstallmentPlanStatusClosed}
                                </Typography>
                                <Typography color='#aaaaaa'>
                                    {`(${((row.totalInstallmentPlanStatusClosed || 0) * 100 / (row.totalInstallmentPlan || 1)).toFixed(1)}%)`}
                                </Typography>
                            </Stack>,
                        header: 'ปิดยอด',
                        enableHiding: false,
                        enablePinning: false,
                        grow: false,
                        enableSorting: false,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                        size: 120,

                    },
                    {
                        id: 'totalInstallmentPlanStatusRefund',
                        accessorFn: (row: InstallmentPlanMonthlySummary) =>
                            <Stack direction={'row'} gap={1} justifyContent='flex-end'>
                                <Typography>
                                    {row.totalInstallmentPlanStatusRefund}
                                </Typography>
                                <Typography color='#aaaaaa'>
                                    {`(${((row.totalInstallmentPlanStatusRefund || 0) * 100 / (row.totalInstallmentPlan || 1)).toFixed(1)}%)`}
                                </Typography>
                            </Stack>,
                        header: 'คืนเงิน',
                        enableHiding: false,
                        enablePinning: false,
                        grow: false,
                        enableSorting: false,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                        size: 120,

                    },
                    {
                        id: 'totalInstallmentPlanStatusFollowingUp',
                        accessorFn: (row: InstallmentPlanMonthlySummary) =>
                            <Stack direction={'row'} gap={1} justifyContent='flex-end'>
                                <Typography>
                                    {row.totalInstallmentPlanStatusFollowingUp}
                                </Typography>
                                <Typography color='#aaaaaa'>
                                    {`(${((row.totalInstallmentPlanStatusFollowingUp || 0) * 100 / (row.totalInstallmentPlan || 1)).toFixed(1)}%)`}
                                </Typography>
                            </Stack>,
                        header: 'ติดตาม',
                        enableHiding: false,
                        enablePinning: false,
                        grow: false,
                        enableSorting: false,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                        size: 120,

                    },
                    {
                        id: 'totalInstallmentPlanStatusCannotContactCustomer',
                        accessorFn: (row: InstallmentPlanMonthlySummary) =>
                            <Stack direction={'row'} gap={1} justifyContent='flex-end'>
                                <Typography>
                                    {row.totalInstallmentPlanStatusCannotContactCustomer}
                                </Typography>
                                <Typography color='#aaaaaa'>
                                    {`(${((row.totalInstallmentPlanStatusCannotContactCustomer || 0) * 100 / (row.totalInstallmentPlan || 1)).toFixed(1)}%)`}
                                </Typography>
                            </Stack>,
                        header: 'ติดต่อไม่ได้',
                        enableHiding: false,
                        enablePinning: false,
                        grow: false,
                        enableSorting: false,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                        size: 120,

                    },
                    {
                        id: 'totalInstallmentPlanStatusReported',
                        accessorFn: (row: InstallmentPlanMonthlySummary) =>
                            <Stack direction={'row'} gap={1} justifyContent='flex-end'>
                                <Typography>
                                    {row.totalInstallmentPlanStatusReported}
                                </Typography>
                                <Typography color='#aaaaaa'>
                                    {`(${((row.totalInstallmentPlanStatusReported || 0) * 100 / (row.totalInstallmentPlan || 1)).toFixed(1)}%)`}
                                </Typography>
                            </Stack>,
                        header: 'แจ้งความ',
                        enableHiding: false,
                        enablePinning: false,
                        grow: false,
                        enableSorting: false,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                        size: 120,
                    },
                    {
                        id: 'totalInstallmentPlanStatusNonPerforming',
                        accessorFn: (row: InstallmentPlanMonthlySummary) =>
                            <Stack direction={'row'} gap={1} justifyContent='flex-end'>
                                <Typography>
                                    {row.totalInstallmentPlanStatusNonPerforming}
                                </Typography>
                                <Typography color='#aaaaaa'>
                                    {`(${((row.totalInstallmentPlanStatusNonPerforming || 0) * 100 / (row.totalInstallmentPlan || 1)).toFixed(1)}%)`}
                                </Typography>
                            </Stack>,
                        header: 'หนี้เสีย',
                        enableHiding: false,
                        enablePinning: false,
                        grow: false,
                        enableSorting: false,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                        size: 120,
                    },
                    {
                        id: 'totalInstallmentPlanCancel',
                        accessorFn: (row: InstallmentPlanMonthlySummary) =>
                            <Stack direction={'row'} gap={1} justifyContent='flex-end'>
                                <Typography>
                                    {row.totalInstallmentPlanCancel}
                                </Typography>
                                <Typography color='#aaaaaa'>
                                    {`(${((row.totalInstallmentPlanCancel || 0) * 100 / (row.totalInstallmentPlan || 1)).toFixed(1)}%)`}
                                </Typography>
                            </Stack>,
                        header: 'ยกเลิก',
                        enableHiding: false,
                        enablePinning: false,
                        grow: false,
                        enableSorting: false,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                        size: 120,

                    },
                    {
                        id: 'totalInstallmentPlan',
                        accessorFn: (row: InstallmentPlanMonthlySummary) =>
                            <Typography sx={{ fontWeight: '500', color: '#0b57d0' }}>{row.totalInstallmentPlan}</Typography>,
                        header: 'รวม',
                        enableHiding: false,
                        enablePinning: false,
                        grow: false,
                        enableSorting: false,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                        size: 120,
                    },
                ]
            },
        ],
        [],
    );

    const installmentPlanAmountColumns = useMemo<MRT_ColumnDef<InstallmentPlanMonthlySummary>[]>(
        () => [
            {
                id: 'month',
                accessorFn: (row: InstallmentPlanMonthlySummary) => {
                    return <Typography>{row.month}</Typography>
                },
                header: 'เดือน',
                enableHiding: false,
                enableSorting: false,
                grow: false,
                size: 40,
                enableResizing: false,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                Header: ({ column }) => (
                    <Box width={40}>
                        <Typography color='black'>{column.columnDef.header}</Typography>
                    </Box>
                ),
            },
            {
                id: 'totalInstallmentPlanAmount',
                accessorFn: (row: InstallmentPlanMonthlySummary) => {
                    return <Typography sx={{ fontWeight: '500', color: '#0b57d0' }}>{currencyFormat.format(row.totalInstallmentPlanAmount || 0)}</Typography>
                }, //simple recommended way to define a column
                header: 'ยอดสินเชื่อ',
                enableHiding: false,
                enablePinning: false,
                enableSorting: false,
                size: 130,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
            },
            {
                id: 'income',
                header: 'รายรับ',
                Header: ({ column }) => (
                    <Typography p={1} color='white'>{column.columnDef.header}</Typography>
                ),
                columns: [
                    {
                        id: 'totalDownPaymentAmount',
                        accessorFn: (row: InstallmentPlanMonthlySummary) =>
                            <Typography >{currencyFormat.format(row.totalDownPaymentAmount || 0)}</Typography>,
                        header: 'ยอดดาวน์',
                        enableHiding: false,
                        enablePinning: false,
                        enableSorting: false,
                        size: 130,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                    },
                    {
                        id: 'totalTermPaidDueAmount',
                        accessorFn: (row: InstallmentPlanMonthlySummary) =>
                            <Typography >{currencyFormat.format(row.totalTermPaidDueAmount || 0)}</Typography>,
                        header: 'ยอดค่างวดจ่ายแล้ว',
                        enableHiding: false,
                        enablePinning: false,
                        enableSorting: false,
                        size: 130,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                    },
                    {
                        id: 'totalTermPaidFineAmount',
                        accessorFn: (row: InstallmentPlanMonthlySummary) =>
                            <Typography >
                                {currencyFormat.format(row.totalTermPaidFineAmount || 0)}
                            </Typography>,
                        header: 'ยอดค่าปรับจ่ายแล้ว',
                        enableHiding: false,
                        enablePinning: false,
                        enableSorting: false,
                        size: 130,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                    },
                    {
                        id: 'totalIncome',
                        accessorFn: (row: InstallmentPlanMonthlySummary) =>
                            <Typography sx={{ fontWeight: '500', color: '#43a047' }}>
                                {currencyFormat.format(row.totalIncome || 0)}
                            </Typography>,
                        header: 'รวม',
                        enableHiding: false,
                        enablePinning: false,
                        enableSorting: false,
                        size: 130,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                    }
                ]
            },
            {
                id: 'cost',
                header: 'ต้นทุน',
                Header: ({ column }) => (
                    <Typography p={1} color='white'>{column.columnDef.header}</Typography>
                ),
                columns: [
                    {
                        id: 'totalPurchaseCost',
                        accessorFn: (row: InstallmentPlanMonthlySummary) =>
                            <Typography >
                                {currencyFormat.format(row.totalPurchaseCost || 0)}
                            </Typography>,
                        header: 'ค่าเครื่อง',
                        enableHiding: false,
                        enablePinning: false,
                        enableSorting: false,
                        size: 130,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                    },
                    {
                        id: 'totalCommission',
                        accessorFn: (row: InstallmentPlanMonthlySummary) =>
                            <Typography >
                                {currencyFormat.format(row.totalCommission || 0)}
                            </Typography>,
                        header: 'ค่าคอม',
                        enableHiding: false,
                        enablePinning: false,
                        enableSorting: false,
                        size: 130,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                    },
                    {
                        id: 'totalOtherCost',
                        accessorFn: (row: InstallmentPlanMonthlySummary) =>
                            <Typography >
                                {currencyFormat.format(row.totalOtherCost || 0)}
                            </Typography>,
                        header: 'ค่าอื่นๆ',
                        enableHiding: false,
                        enablePinning: false,
                        enableSorting: false,
                        size: 130,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                    },
                    {
                        id: 'totalCost',
                        accessorFn: (row: InstallmentPlanMonthlySummary) =>
                            <Typography sx={{ fontWeight: '500', color: '#f44336' }} >
                                {currencyFormat.format(row.totalCost || 0)}
                            </Typography>,
                        header: 'รวม',
                        enableHiding: false,
                        enablePinning: false,
                        enableSorting: false,
                        size: 130,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                    }
                ]
            },
            {
                id: 'totalProfit',
                Header: ({ column }) => (
                    <Typography p={1} color='white'>{column.columnDef.header}</Typography>
                ),
                header: 'สรุป',
                enableHiding: false,
                enablePinning: false,
                enableSorting: false,
                size: 130,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                columns: [{
                    id: 'totalProfit',
                    accessorFn: (row: InstallmentPlanMonthlySummary) =>
                        <Typography sx={{ fontWeight: '500', color: (row.totalProfit || 0) > 0 ? '#43a047' : (row.totalProfit || 0) < 0 ? '#f44336' : 'black' }}>
                            {currencyFormat.format(row.totalProfit || 0)}
                        </Typography>,
                    header: 'กำไร / ขาดทุน',
                    enableHiding: false,
                    enablePinning: false,
                    enableSorting: false,
                    size: 130,
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    muiTableBodyCellProps: {
                        align: 'right',
                    },
                }]
            },
        ],
        [currencyFormat],
    );

    const installmentPlanCountTable = useMaterialReactTable({
        columns: installmentPlanCountColumns,
        data: shopDashboardQuery.data?.installmentPlanMonthlySummaries || [],
        layoutMode: 'semantic',
        enablePagination: false,
        enableColumnPinning: true,
        enableGlobalFilter: false,
        enableColumnFilters: false,
        enableSorting: true,
        enableHiding: false,
        enableColumnActions: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableColumnVirtualization: false,
        enableColumnOrdering: false,
        enableBottomToolbar: false,
        positionGlobalFilter: 'left',
        enableTopToolbar: false,
        manualSorting: true,
        paginationDisplayMode: 'pages',
        state: {
            isLoading: shopDashboardQuery.isFetching,
        },
        localization: {
            rowsPerPage: "จำนวนต่อหน้า"
        },
        manualPagination: true,
        initialState: {
            showGlobalFilter: false,
            columnPinning: {
                left: ['month'],
            },
        },
        positionActionsColumn: 'last',
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: '', //change header text
                size: 12, //make actions column wider
            },
        },
        muiTableHeadCellProps: {
            sx: {
                borderRight: '1px solid #e0e0e0', //add a border between columns
                backgroundColor: '#455a64',
                color: 'white',
                fontWeight: '500',
                padding: '8px',
            },
        },
        muiTableBodyProps: {
            sx: {
                '& tr:nth-of-type(even):not([data-selected="true"])) > td': {
                    backgroundColor: 'grey.100',
                },
            },
        },
        muiTableBodyCellProps: {
            sx: {
                padding: '2px 8px',
                borderRight: '1px solid #eeeeee', //add a border between columns
            },
        },

    });

    const installmentPlanAmountTable = useMaterialReactTable({
        columns: installmentPlanAmountColumns,
        data: shopDashboardQuery.data?.installmentPlanMonthlySummaries || [],
        layoutMode: 'semantic',
        enablePagination: false,
        enableColumnPinning: true,
        enableGlobalFilter: false,
        enableColumnFilters: false,
        enableSorting: true,
        enableHiding: false,
        enableColumnActions: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableColumnVirtualization: false,
        enableColumnOrdering: false,
        enableBottomToolbar: false,
        positionGlobalFilter: 'left',
        enableTopToolbar: false,
        manualSorting: true,
        paginationDisplayMode: 'pages',
        state: {
            isLoading: shopDashboardQuery.isFetching,
        },
        localization: {
            rowsPerPage: "จำนวนต่อหน้า"
        },
        manualPagination: true,
        initialState: {
            showGlobalFilter: false,
            columnPinning: {
                left: ['month'],
            },
        },
        positionActionsColumn: 'last',
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: '', //change header text
                size: 12, //make actions column wider
            },
        },
        muiTableHeadCellProps: {
            sx: {
                borderRight: '1px solid #e0e0e0', //add a border between columns
                backgroundColor: '#455a64',
                color: 'white',
                fontWeight: '500',
                padding: '8px',
            },
        },
        muiTableBodyProps: {
            sx: {
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td': {
                    backgroundColor: 'grey.100',
                },
            },
        },
        muiTableBodyCellProps: {
            sx: {
                padding: '2px 8px',
                borderRight: '1px solid #eeeeee', //add a border between columns
            },
        },

    });

    return (
        <Box p={2}>
            <Box sx={{ width: { xs: '90%', md: '40%' } }}>
                <Stack direction={{ md: 'row' }} gap={2} >
                    <TextField
                        sx={{ backgroundColor: 'white' }}
                        label="ปี"
                        value={selectedYear}
                        onChange={handleYearChange}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <FormControl sx={{ width: '300px' }} fullWidth>
                        <InputLabel id="demo-simple-select-label">เดือน</InputLabel>
                        <Select
                            sx={{ backgroundColor: 'white' }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedMonth}
                            onChange={(e) => setSelectedMonth(e.target.value as number)}
                            label="เดือน"
                        >
                            <MenuItem value={1}>มกราคม</MenuItem>
                            <MenuItem value={2}>กุมภาพันธ์</MenuItem>
                            <MenuItem value={3}>มีนาคม</MenuItem>
                            <MenuItem value={4}>เมษายน</MenuItem>
                            <MenuItem value={5}>พฤษภาคม</MenuItem>
                            <MenuItem value={6}>มิถุนายน</MenuItem>
                            <MenuItem value={7}>กรกฎาคม</MenuItem>
                            <MenuItem value={8}>สิงหาคม</MenuItem>
                            <MenuItem value={9}>กันยายน</MenuItem>
                            <MenuItem value={10}>ตุลาคม</MenuItem>
                            <MenuItem value={11}>พฤศจิกายน</MenuItem>
                            <MenuItem value={12}>ธันวาคม</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ mt: 4, py: 2 }}>
                    สรุปยอดแยกเดือน
                </Typography>
                <Stack gap={4}>
                    <MaterialReactTable table={installmentPlanAmountTable} />
                </Stack>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ mt: 4, py: 2 }}>
                    สรุปสถานะแยกเดือน
                </Typography>
                <Stack gap={4}>
                    <MaterialReactTable table={installmentPlanCountTable} />
                </Stack>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ mt: 4, py: 2 }}>
                    {`กระแสรายเดือน ${selectedMonth} ปี ${selectedYear}`}
                </Typography>
                <Stack direction={{ lg: 'row' }} sx={{ pb: 2 }} gap={2} useFlexGap flexWrap="wrap">
                    <Card sx={{ width: { xs: '100%', lg: '31%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            <Stack alignContent='stretch' justifyContent='space-evenly'>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Typography variant="body1" component="div">
                                        จำนวนสินเชื่อใหม่
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Typography variant="h2" component="div" >
                                        {shopDashboardQuery.data?.monthlySummary?.installmentPlanCount}
                                    </Typography>
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Card sx={{ width: { lg: '33%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            <Stack alignContent='stretch' justifyContent='space-evenly'>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Typography variant="body1" component="div">
                                        {`ค่างวดเรียกเก็บ (${shopDashboardQuery.data?.monthlySummary?.installmentTermCount || 0} รายการ)`}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Typography variant="h2" component="div" color='#9e9e9e'>
                                        {currencyFormat.format(shopDashboardQuery.data?.monthlySummary?.installmentTermDueAmount || 0)}
                                    </Typography>
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Card sx={{ width: { lg: '33%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            <Stack alignContent='stretch' justifyContent='space-evenly'>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Typography variant="body1" component="div">
                                        {`ค่างวดเก็บได้จริง (${shopDashboardQuery.data?.monthlySummary?.installmentTermPaymentCount || 0} รายการ)`}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Typography variant="h2" component="div" color='#aed581'>
                                        {currencyFormat.format(shopDashboardQuery.data?.monthlySummary?.installmentTermPaymentPaidDueAmount || 0)}
                                    </Typography>
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
                <Stack direction={{ lg: 'row' }} sx={{ width: '100%', pb: 2 }} gap={2} useFlexGap flexWrap="wrap">
                    <Card sx={{ width: { lg: '49%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            {shopDashboardQuery.isFetched
                                &&
                                <Stack alignContent='stretch' justifyContent='space-evenly'>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="body1" component="div">
                                            {`รายได้ (${shopDashboardQuery.data?.monthlySummary?.installmentPaymentCount || 0} รายการ)`}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="h2" component="div">
                                            {currencyFormat.format(shopDashboardQuery.data?.monthlySummary?.installmentPaymentAmount || 0)}
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                    <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-evenly'>
                                        <Stack alignContent='center' alignItems='center'>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="body2" component="div">
                                                    {`เงินดาว (${shopDashboardQuery.data?.monthlySummary?.downPaymentCount || 0} รายการ)`}
                                                </Typography>
                                            </Box>
                                            <Typography variant="h5" color='#9e9e9e'>
                                                {currencyFormat.format(shopDashboardQuery.data?.monthlySummary?.downPaymentAmount || 0)}
                                            </Typography>
                                        </Stack>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <Stack alignContent='center' alignItems='center'>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="body2" component="div">
                                                    {`ค่างวด (${shopDashboardQuery.data?.monthlySummary?.installmentTermPaymentCount || 0} รายการ)`}
                                                </Typography>
                                            </Box>
                                            <Typography variant="h5" color='#aed581'>
                                                {currencyFormat.format(shopDashboardQuery.data?.monthlySummary?.installmentTermPaymentPaidDueAmount || 0)}
                                            </Typography>
                                        </Stack>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <Stack alignContent='center' alignItems='center'>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="body2" component="div" >
                                                    {`ค่าปรับ`}
                                                </Typography>
                                            </Box>
                                            <Typography variant="h5" color='#03a9f4'>
                                                {currencyFormat.format(shopDashboardQuery.data?.monthlySummary?.installmentTermPaymentPaidFineAmount || 0)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            }

                        </CardContent>
                    </Card>

                    <Card sx={{ width: { lg: '49%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            {shopDashboardQuery.isFetched
                                &&
                                <Stack alignContent='stretch' justifyContent='space-evenly'>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="body1" component="div">
                                            รายจ่าย
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="h2" component="div" color='#ef9a9a'>
                                            {currencyFormat.format(shopDashboardQuery.data?.monthlySummary?.expenseAmount || 0)}
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                    <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-evenly'>
                                        <Stack alignContent='center' alignItems='center'>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="body2" component="div">
                                                    ต้นทุนเครื่อง
                                                </Typography>
                                            </Box>
                                            <Typography variant="h5" color='#9e9e9e'>
                                                {currencyFormat.format(shopDashboardQuery.data?.monthlySummary?.purchaseCostAmount || 0)}
                                            </Typography>
                                        </Stack>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <Stack alignContent='center' alignItems='center'>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="body2" component="div">
                                                    ค่าคอม
                                                </Typography>
                                            </Box>
                                            <Typography variant="h5" color='#9e9e9e'>
                                                {currencyFormat.format(shopDashboardQuery.data?.monthlySummary?.commissionAmount || 0)}
                                            </Typography>
                                        </Stack>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <Stack alignContent='center' alignItems='center'>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="body2" component="div">
                                                    ต้นทุนอื่นๆ
                                                </Typography>
                                            </Box>
                                            <Typography variant="h5" color='#9e9e9e'>
                                                {currencyFormat.format(shopDashboardQuery.data?.monthlySummary?.otherCostAmount || 0)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            }

                        </CardContent>
                    </Card>
                </Stack>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ mt: 4, py: 2 }}>
                    {`กระแสรายปี ${selectedYear}`}
                </Typography>
                <Stack direction={{ lg: 'row' }} sx={{ pb: 2 }} gap={2} useFlexGap flexWrap="wrap">
                    <Card sx={{ width: { xs: '100%', lg: '31%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            <Stack alignContent='stretch' justifyContent='space-evenly'>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Typography variant="body1" component="div">
                                        จำนวนสินเชื่อใหม่
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Typography variant="h2" component="div" >
                                        {shopDashboardQuery.data?.yearlySummary?.installmentPlanCount}
                                    </Typography>
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Card sx={{ width: { lg: '33%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            <Stack alignContent='stretch' justifyContent='space-evenly'>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Typography variant="body1" component="div">
                                        {`ค่างวดเรียกเก็บ (${shopDashboardQuery.data?.yearlySummary?.installmentTermCount || 0} รายการ)`}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Typography variant="h2" component="div" color='#9e9e9e'>
                                        {currencyFormat.format(shopDashboardQuery.data?.yearlySummary?.installmentTermCount || 0)}
                                    </Typography>
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Card sx={{ width: { lg: '33%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            <Stack alignContent='stretch' justifyContent='space-evenly'>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Typography variant="body1" component="div">
                                        {`ค่างวดเก็บได้จริง (${shopDashboardQuery.data?.yearlySummary?.installmentTermPaymentCount || 0} รายการ)`}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Typography variant="h2" component="div" color='#aed581'>
                                        {currencyFormat.format(shopDashboardQuery.data?.yearlySummary?.installmentTermPaymentPaidDueAmount || 0)}
                                    </Typography>
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
                <Stack direction={{ lg: 'row' }} sx={{ width: '100%', pb: 2 }} gap={2} useFlexGap flexWrap="wrap">
                    <Card sx={{ width: { lg: '49%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            {shopDashboardQuery.isFetched
                                &&
                                <Stack alignContent='stretch' justifyContent='space-evenly'>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="body1" component="div">
                                            {`รายได้ (${shopDashboardQuery.data?.yearlySummary?.installmentPaymentCount || 0} รายการ)`}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="h2" component="div">
                                            {currencyFormat.format(shopDashboardQuery.data?.yearlySummary?.installmentPaymentAmount || 0)}
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                    <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-evenly'>
                                        <Stack alignContent='center' alignItems='center'>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="body2" component="div">
                                                    {`เงินดาว (${shopDashboardQuery.data?.yearlySummary?.downPaymentCount || 0} รายการ)`}
                                                </Typography>
                                            </Box>
                                            <Typography variant="h5" color='#9e9e9e'>
                                                {currencyFormat.format(shopDashboardQuery.data?.yearlySummary?.downPaymentAmount || 0)}
                                            </Typography>
                                        </Stack>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <Stack alignContent='center' alignItems='center'>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="body2" component="div">
                                                    {`ค่างวด (${shopDashboardQuery.data?.yearlySummary?.installmentTermPaymentCount || 0} รายการ)`}
                                                </Typography>
                                            </Box>
                                            <Typography variant="h5" color='#aed581'>
                                                {currencyFormat.format(shopDashboardQuery.data?.yearlySummary?.installmentTermPaymentPaidDueAmount || 0)}
                                            </Typography>
                                        </Stack>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <Stack alignContent='center' alignItems='center'>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="body2" component="div" >
                                                    {`ค่าปรับ`}
                                                </Typography>
                                            </Box>
                                            <Typography variant="h5" color='#03a9f4'>
                                                {currencyFormat.format(shopDashboardQuery.data?.yearlySummary?.installmentTermPaymentPaidFineAmount || 0)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            }
                        </CardContent>
                    </Card>
                    <Card sx={{ width: { lg: '49%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            {shopDashboardQuery.isFetched
                                &&
                                <Stack alignContent='stretch' justifyContent='space-evenly'>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="body1" component="div">
                                            รายจ่าย
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="h2" component="div" color='#ef9a9a'>
                                            {currencyFormat.format(shopDashboardQuery.data?.yearlySummary?.expenseAmount || 0)}
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                    <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-evenly'>
                                        <Stack alignContent='center' alignItems='center'>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="body2" component="div">
                                                    ต้นทุนเครื่อง
                                                </Typography>
                                            </Box>
                                            <Typography variant="h5" color='#9e9e9e'>
                                                {currencyFormat.format(shopDashboardQuery.data?.yearlySummary?.purchaseCostAmount || 0)}
                                            </Typography>
                                        </Stack>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <Stack alignContent='center' alignItems='center'>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="body2" component="div">
                                                    ค่าคอม
                                                </Typography>
                                            </Box>
                                            <Typography variant="h5" color='#9e9e9e'>
                                                {currencyFormat.format(shopDashboardQuery.data?.yearlySummary?.commissionAmount || 0)}
                                            </Typography>
                                        </Stack>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <Stack alignContent='center' alignItems='center'>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="body2" component="div">
                                                    ต้นทุนอื่นๆ
                                                </Typography>
                                            </Box>
                                            <Typography variant="h5" color='#9e9e9e'>
                                                {currencyFormat.format(shopDashboardQuery.data?.yearlySummary?.otherCostAmount || 0)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            }
                        </CardContent>
                    </Card>
                </Stack>
            </Box>

            <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ mt: 4, py: 2 }}>
                    {`กระแสรวมทั้งหมด`}
                </Typography>
                <Stack direction={{ lg: 'row' }} sx={{ pb: 2 }} gap={2} useFlexGap flexWrap="wrap">
                    <Card sx={{ width: { xs: '100%', lg: '31%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            <Stack alignContent='stretch' justifyContent='space-evenly'>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Typography variant="body1" component="div">
                                        จำนวนสินเชื่อใหม่
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Typography variant="h2" component="div" >
                                        {shopDashboardQuery.data?.overallSummary?.installmentPlanCount}
                                    </Typography>
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Card sx={{ width: { lg: '33%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            <Stack alignContent='stretch' justifyContent='space-evenly'>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Typography variant="body1" component="div">
                                        {`ค่างวดเรียกเก็บ (${shopDashboardQuery.data?.overallSummary?.installmentTermCount || 0} รายการ)`}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Typography variant="h2" component="div" color='#9e9e9e'>
                                        {currencyFormat.format(shopDashboardQuery.data?.overallSummary?.installmentTermCount || 0)}
                                    </Typography>
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Card sx={{ width: { lg: '33%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            <Stack alignContent='stretch' justifyContent='space-evenly'>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Typography variant="body1" component="div">
                                        {`ค่างวดเก็บได้จริง (${shopDashboardQuery.data?.overallSummary?.installmentTermPaymentCount || 0} รายการ)`}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <Typography variant="h2" component="div" color='#aed581'>
                                        {currencyFormat.format(shopDashboardQuery.data?.overallSummary?.installmentTermPaymentPaidDueAmount || 0)}
                                    </Typography>
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
                <Stack direction={{ lg: 'row' }} sx={{ width: '100%', pb: 2 }} gap={2} useFlexGap flexWrap="wrap">
                    <Card sx={{ width: { lg: '49%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            {shopDashboardQuery.isFetched
                                &&
                                <Stack alignContent='stretch' justifyContent='space-evenly'>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="body1" component="div">
                                            {`รายได้ (${shopDashboardQuery.data?.overallSummary?.installmentPaymentCount || 0} รายการ)`}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="h2" component="div">
                                            {currencyFormat.format(shopDashboardQuery.data?.overallSummary?.installmentPaymentAmount || 0)}
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                    <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-evenly'>
                                        <Stack alignContent='center' alignItems='center'>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="body2" component="div">
                                                    {`เงินดาว (${shopDashboardQuery.data?.overallSummary?.downPaymentCount || 0} รายการ)`}
                                                </Typography>
                                            </Box>
                                            <Typography variant="h5" color='#9e9e9e'>
                                                {currencyFormat.format(shopDashboardQuery.data?.overallSummary?.downPaymentAmount || 0)}
                                            </Typography>
                                        </Stack>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <Stack alignContent='center' alignItems='center'>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="body2" component="div">
                                                    {`ค่างวด (${shopDashboardQuery.data?.overallSummary?.installmentTermPaymentCount || 0} รายการ)`}
                                                </Typography>
                                            </Box>
                                            <Typography variant="h5" color='#aed581'>
                                                {currencyFormat.format(shopDashboardQuery.data?.overallSummary?.installmentTermPaymentPaidDueAmount || 0)}
                                            </Typography>
                                        </Stack>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <Stack alignContent='center' alignItems='center'>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="body2" component="div" >
                                                    {`ค่าปรับ`}
                                                </Typography>
                                            </Box>
                                            <Typography variant="h5" color='#03a9f4'>
                                                {currencyFormat.format(shopDashboardQuery.data?.overallSummary?.installmentTermPaymentPaidFineAmount || 0)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            }
                        </CardContent>
                    </Card>
                    <Card sx={{ width: { lg: '49%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            {shopDashboardQuery.isFetched
                                &&
                                <Stack alignContent='stretch' justifyContent='space-evenly'>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="body1" component="div">
                                            รายจ่าย
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="h2" component="div" color='#ef9a9a'>
                                            {currencyFormat.format(shopDashboardQuery.data?.overallSummary?.expenseAmount || 0)}
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                    <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-evenly'>
                                        <Stack alignContent='center' alignItems='center'>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="body2" component="div">
                                                    ต้นทุนเครื่อง
                                                </Typography>
                                            </Box>
                                            <Typography variant="h5" color='#9e9e9e'>
                                                {currencyFormat.format(shopDashboardQuery.data?.overallSummary?.purchaseCostAmount || 0)}
                                            </Typography>
                                        </Stack>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <Stack alignContent='center' alignItems='center'>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="body2" component="div">
                                                    ค่าคอม
                                                </Typography>
                                            </Box>
                                            <Typography variant="h5" color='#9e9e9e'>
                                                {currencyFormat.format(shopDashboardQuery.data?.overallSummary?.commissionAmount || 0)}
                                            </Typography>
                                        </Stack>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <Stack alignContent='center' alignItems='center'>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="body2" component="div">
                                                    ต้นทุนอื่นๆ
                                                </Typography>
                                            </Box>
                                            <Typography variant="h5" color='#9e9e9e'>
                                                {currencyFormat.format(shopDashboardQuery.data?.yearlySummary?.otherCostAmount || 0)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            }
                        </CardContent>
                    </Card>
                </Stack>
            </Box>
        </Box >
    )
}