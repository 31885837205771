/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BusinessExceptionResponse = {
    code?: BusinessExceptionResponse.code;
    description?: string;
};
export namespace BusinessExceptionResponse {
    export enum code {
        TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND',
        MISSING_REQUIRED_FIELDS = 'MISSING_REQUIRED_FIELDS',
        SHOP_NOT_FOUND = 'SHOP_NOT_FOUND',
        SHOP_PAYMENT_CHANNEL_NOT_FOUND = 'SHOP_PAYMENT_CHANNEL_NOT_FOUND',
        SHOP_USER_INVALID_CONDITION = 'SHOP_USER_INVALID_CONDITION',
        INSTALLMENT_PLAN_NOT_FOUND = 'INSTALLMENT_PLAN_NOT_FOUND',
        INSTALLMENT_PLAN_STATUS_MISMATCH = 'INSTALLMENT_PLAN_STATUS_MISMATCH',
        INSTALLMENT_TERM_NOT_FOUND = 'INSTALLMENT_TERM_NOT_FOUND',
        INSTALLMENT_PAYMENT_NOT_FOUND = 'INSTALLMENT_PAYMENT_NOT_FOUND',
        INSTALLMENT_PAYMENT_CANNOT_DELETE = 'INSTALLMENT_PAYMENT_CANNOT_DELETE',
        LOAN_STATUS_MISMATCH = 'LOAN_STATUS_MISMATCH',
        LOAN_INVALID_INFORMATION = 'LOAN_INVALID_INFORMATION',
        LOAN_REGISTRATION_STATUS_MISMATCH = 'LOAN_REGISTRATION_STATUS_MISMATCH',
        LOAN_REGISTRATION_MISSING_REQUIRED_FIELD = 'LOAN_REGISTRATION_MISSING_REQUIRED_FIELD',
        CUSTOMER_NOT_FOUND = 'CUSTOMER_NOT_FOUND',
        DEVICE_NOT_FOUND = 'DEVICE_NOT_FOUND',
        USER_NOT_FOUND = 'USER_NOT_FOUND',
        MEMBER_NOT_FOUND = 'MEMBER_NOT_FOUND',
        ACCESS_DENIED = 'ACCESS_DENIED',
        API_CALL_ERROR = 'API_CALL_ERROR',
        UNHANDLED = 'UNHANDLED',
    }
}

