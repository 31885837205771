import { Box, Typography } from "@mui/material";

interface DataCellProps {
    label: string;
    backgroundColor?: string;
}

export const DataCell: React.FC<DataCellProps> = ({ label, backgroundColor }) => {
    return (
        <Box sx={{
            padding: 1,
            border: '1px solid #e0e0e0',
            backgroundColor: backgroundColor ? backgroundColor : 'white',
            height: 52,
            display: 'flex',
            alignItems: 'center'
        }}>
            <Typography noWrap>{label.trim() ? label : '-'}</Typography>
        </Box>
    )
}