/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ClerkWebhookService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns any OK
     * @throws ApiError
     */
    public clerkSessionEvent({
        httpEntity,
    }: {
        httpEntity: string,
    }): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/webhooks/clerk-session',
            query: {
                'httpEntity': httpEntity,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
