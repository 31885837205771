import React from 'react';
import { CircularProgress, Box } from '@mui/material';

interface SpinProps {
    spinning?: boolean;
    tip?: string;
    children?: React.ReactNode;
}

export const Spin: React.FC<SpinProps> = ({ spinning = false, tip, children }) => {
    const overlayStyle: React.CSSProperties = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh', // Set height to full viewport height
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        opacity: spinning ? 1 : 0, // Adjust opacity based on `spinning`
        transition: 'opacity 0.3s ease', // Smooth transition for opacity
        zIndex: 9999,
    };

    const containerStyle: React.CSSProperties = {
        position: 'relative',
    };

    const contentStyle: React.CSSProperties = {
        opacity: spinning ? 0.5 : 1,
        pointerEvents: spinning ? 'none' : 'auto',
    };

    const tipStyle: React.CSSProperties = {
        marginTop: 8,
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: 'small',
        textAlign: 'center',
    };

    return (
        <div style={containerStyle}>
            {spinning && (
                <div style={overlayStyle}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <CircularProgress />
                        {tip && <div style={tipStyle}>{tip}</div>}
                    </Box>
                </div>
            )}
            <div style={contentStyle}>{children}</div>
        </div>
    );
};