import { useEffect, useState } from "react"

import DropdownChip from "./DropDownChip"
import { InstallmentPlanDto } from "../client/motalvip-apis/generated"
// import { LoanEvent } from "../client/motal-api/generated"

type LoanStatusChipProps = {
    status?: InstallmentPlanDto.status
    selectable?: boolean
    onSelect?: (option: InstallmentPlanDto.status) => void;
    disabled?: boolean;
}

export const loanStatusoptions = [
    { value: InstallmentPlanDto.status.DRAFT, display: 'รอข้อมูลการสมัคร' },
    { value: InstallmentPlanDto.status.PENDING_CONTRACT_DOCUMENTS, display: 'รอเอกสารสัญญา' },
    { value: InstallmentPlanDto.status.PENDING_CONFIRM_DOWN_PAYMENT, display: 'รอจ่ายเงินดาวน์' },
    { value: InstallmentPlanDto.status.NORMAL, display: 'กำลังผ่อน' },
    { value: InstallmentPlanDto.status.CLOSED, display: 'ปิดสินเชื่อ' },
    { value: InstallmentPlanDto.status.REFUND, display: 'คืนเครื่อง' },
    { value: InstallmentPlanDto.status.FOLLOWING_UP, display: 'ติดตามเครื่อง' },
    { value: InstallmentPlanDto.status.CANNOT_CONTACT_CUSTOMER, display: 'ติดต่อไม่ได้' },
    { value: InstallmentPlanDto.status.NON_PERFORMING, display: 'หนี้เสีย' },
    { value: InstallmentPlanDto.status.REPORTED, display: 'แจ้งความ' },
    { value: InstallmentPlanDto.status.CANCEL, display: 'ยกเลิก' },
];

export const LoanStatusChip: React.FC<LoanStatusChipProps> = ({ disabled, status, selectable = false, onSelect = () => { } }) => {

    const [selectedOption, setSelectedOption] = useState<InstallmentPlanDto.status | undefined>(status);

    useEffect(() => {
        setSelectedOption(status)
    }, [status])

    const handleSelect = (option: InstallmentPlanDto.status) => {
        setSelectedOption(option);
        onSelect(option);
    }

    const colorMapping = {
        'รอข้อมูลการสมัคร': '#757575',
        'รอเอกสารสัญญา': '#1e88e5',
        'รอจ่ายเงินดาวน์': '#1e88e5',
        'กำลังผ่อน': '#1e88e5',
        'ปิดสินเชื่อ': '#aed581',
        'คืนเครื่อง': '#e0e0e0',
        'ติดตามเครื่อง': '#ef9a9a',
        'ติดต่อไม่ได้': '#ef9a9a',
        'หนี้เสีย': '#ef9a9a',
        'แจ้งความ': '#ef9a9a',
        'ยกเลิก': '#e0e0e0',
    };

    return (
        <DropdownChip
            disabled={disabled}
            label={loanStatusoptions.find(o => o.value === selectedOption)?.display || ''}
            options={loanStatusoptions}
            onSelect={handleSelect}
            colorMapping={colorMapping}
            selectable={selectable}
            variant='outlined'
        />
    )

}