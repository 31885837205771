/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedInstallmentHistoryDto } from '../models/PaginatedInstallmentHistoryDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class HistoryService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns PaginatedInstallmentHistoryDto OK
     * @throws ApiError
     */
    public searchHistory({
        search,
        sortBy = 'created_at',
        sort = 'desc',
        page = 1,
        limit = 20,
    }: {
        search?: string,
        sortBy?: string,
        sort?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedInstallmentHistoryDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/histories/search',
            query: {
                'search': search,
                'sortBy': sortBy,
                'sort': sort,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
