/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ShopUserInvitationRequest = {
    shopId?: string;
    email?: string;
    role?: ShopUserInvitationRequest.role;
};
export namespace ShopUserInvitationRequest {
    export enum role {
        OWNER = 'OWNER',
        STAFF = 'STAFF',
    }
}

