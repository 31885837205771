import { Stack, Typography } from "@mui/material";

interface Props {
    label: string,
    children?: React.ReactNode;
}

export const CustomDataField = (props: Props) => {
    return (
        <Stack direction={{ sm: 'column', md: 'row' }}>
            <Stack justifyContent={'center'} p={1} sx={{ width: { sm: '100%', md: '25%' }, border: '1px solid #dddddd', backgroundColor: '#eeeeee' }}>
                <Typography>{props.label}</Typography>
            </Stack>
            <Stack justifyContent={'center'} p={1} sx={{ width: { sm: '100%', md: '75%' }, border: '1px solid #dddddd' }}>
                {props.children}
            </Stack>
        </Stack>
    )
}