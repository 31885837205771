import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import buddhistEra from "dayjs/plugin/buddhistEra";
import { MaterialReactTable, MRT_SortingState, useMaterialReactTable } from 'material-react-table';
import { useEffect, useState } from 'react';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { InstallmentPlanDto } from '../../../client/motalvip-apis/generated';
import { useLoansQuery } from '../../../client/query-client';
import { useShopContext } from '../../../context/ShopContextProvider';
import { LoanApprovalColumnDef } from '../../../util/LoanColumnDef';

dayjs.extend(buddhistEra);

export const LoanApproveListScreen = () => {
    const nav = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const { shopId } = useParams();
    const [search, setSearch] = useState<string>(searchParams.get('search') || '');
    const [pagination, setPagination] = useState({
        pageIndex: searchParams.get('page') ? parseInt(searchParams.get('page') || '0') : 0, //customize the default page index
        pageSize: searchParams.get('limit') ? parseInt(searchParams.get('limit') || '10') : 10, //customize the default page size
    });
    const [sorting, setSorting] = useState<MRT_SortingState>([{
        id: searchParams.get('sorting') || 'sequenceNumber',
        desc: searchParams.get('sort') === 'desc'
    }]);
    const shopCtx = useShopContext();

    useEffect(() => {
        const searchParams = new URLSearchParams();
        searchParams.set('search', search);
        searchParams.set('page', pagination.pageIndex.toString());
        searchParams.set('limit', pagination.pageSize.toString());
        // filterStatus.forEach(o => searchParams.append('status', o.value));
        if (sorting.length > 0) {
            searchParams.set('sorting', sorting[0].id);
            searchParams.set('sort', sorting[0].desc ? 'desc' : 'asc');
        }
        setSearchParams(searchParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }
        , [search, pagination, sorting, setSearchParams]
    );

    const loansQuery = useLoansQuery({
        shopId: shopId || '',
        search: search.length >= 3 ? search : '',
        status: [
            InstallmentPlanDto.status.DRAFT,
            InstallmentPlanDto.status.NORMAL,
            InstallmentPlanDto.status.PENDING_CONTRACT_DOCUMENTS,
            InstallmentPlanDto.status.PENDING_CONFIRM_DOWN_PAYMENT
        ],
        registrationStatus: [
            InstallmentPlanDto.registrationStatus.APPROVED,
            InstallmentPlanDto.registrationStatus.REJECTED
        ],
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
        sortBy: sorting.length > 0 ? sorting[0].id : undefined,
        sort: sorting.length > 0 ? sorting[0].desc ? 'desc' : 'asc' : undefined,
    });

    const loanWaitingApprovalQuery = useLoansQuery({
        shopId: shopId || '',
        search: search.length >= 3 ? search : '',
        status: [
            InstallmentPlanDto.status.DRAFT,
            InstallmentPlanDto.status.NORMAL,
            InstallmentPlanDto.status.PENDING_CONTRACT_DOCUMENTS,
            InstallmentPlanDto.status.PENDING_CONFIRM_DOWN_PAYMENT
        ],
        registrationStatus: [
            InstallmentPlanDto.registrationStatus.PENDING_APPROVAL
        ],
    })

    useEffect(() => {
        loansQuery.refetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination])

    const table = useMaterialReactTable({
        columns: LoanApprovalColumnDef,
        data: loansQuery.data?.content || [],
        layoutMode: 'semantic',
        enableColumnPinning: true,
        enableGlobalFilter: true,
        enableColumnFilters: false,
        enableSorting: true,
        enableHiding: false,
        enableColumnActions: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableColumnVirtualization: false,
        enableColumnOrdering: false,
        positionGlobalFilter: 'left',
        enableTopToolbar: false,
        manualSorting: true,
        paginationDisplayMode: 'pages',
        onSortingChange: setSorting,
        muiPaginationProps: {
            shape: 'rounded',
            page: pagination.pageIndex + 1,
            rowsPerPageOptions: [10, 25, 50, 100],
            defaultPage: 0,
            showRowsPerPage: true,
            showFirstButton: false,
        },
        localization: {
            rowsPerPage: "จำนวนต่อหน้า"
        },
        manualPagination: true,
        rowCount: loansQuery.data?.pagination?.totalElements || 1,
        onPaginationChange: setPagination,
        initialState: {
            showGlobalFilter: true,
            pagination: {
                pageIndex: 0,
                pageSize: 10
            },
            columnPinning: {
                right: ['mrt-row-actions'],
            },
        },
        state: {
            isLoading: loansQuery.isFetching,
            pagination,
            sorting,
            columnVisibility: { 'totalProfit': shopCtx.isOwner },
        },
        enableRowActions: true,
        positionActionsColumn: 'last',
        renderRowActions: ({ row }) => (
            <IconButton onClick={() => {
                nav({
                    pathname: `/${shopId}/loan/approval`,
                    search: createSearchParams({
                        loanId: row.original?.id || ''
                    }).toString()
                })
            }}
            >
                <ArrowForwardIosIcon />
            </IconButton>
        ),
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: '', //change header text
                size: 12, //make actions column wider
            },
        },
        muiTableHeadCellProps: {
            sx: {
                borderRight: '1px solid #e0e0e0', //add a border between columns
                backgroundColor: '#455a64',
                color: 'white',
                fontWeight: '500'
            },
        },
        muiTableBodyProps: {
            sx: {
                //stripe the rows, make odd rows a darker color
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td': {
                    backgroundColor: 'grey.100',
                },
            },
        },
        muiTableBodyCellProps: {
            sx: {
                borderRight: '1px solid #eeeeee', //add a border between columns
            },
        },
    });

    const waitingApprovalTable = useMaterialReactTable({
        columns: LoanApprovalColumnDef,
        data: loanWaitingApprovalQuery.data?.content || [],
        layoutMode: 'semantic',
        enableColumnPinning: true,
        enableGlobalFilter: true,
        enableColumnFilters: false,
        enableSorting: true,
        enableHiding: false,
        enableColumnActions: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableColumnVirtualization: false,
        enableColumnOrdering: false,
        positionGlobalFilter: 'left',
        enablePagination: false,
        enableTopToolbar: false,
        enableBottomToolbar: false,
        manualSorting: true,
        onSortingChange: setSorting,
        muiPaginationProps: {
            shape: 'rounded',
            page: pagination.pageIndex + 1,
            rowsPerPageOptions: [10, 25, 50, 100],
            defaultPage: 0,
            showRowsPerPage: true,
            showFirstButton: false,
        },
        localization: {
            rowsPerPage: "จำนวนต่อหน้า"
        },
        manualPagination: true,
        rowCount: loansQuery.data?.pagination?.totalElements || 1,
        onPaginationChange: setPagination,
        initialState: {
            showGlobalFilter: true,
            pagination: {
                pageIndex: 0,
                pageSize: 10
            },
            columnPinning: {
                right: ['mrt-row-actions'],
            },
        },
        state: {
            isLoading: loansQuery.isFetching,
            pagination,
            sorting,
            columnVisibility: { 'totalProfit': shopCtx.isOwner },
        },
        enableRowActions: true,
        positionActionsColumn: 'last',
        renderRowActions: ({ row }) => (
            <IconButton onClick={() => {
                nav({
                    pathname: `/${shopId}/loan/approval`,
                    search: createSearchParams({
                        loanId: row.original?.id || ''
                    }).toString()
                })
            }}
            >
                <ArrowForwardIosIcon />
            </IconButton>
        ),
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: '', //change header text
                size: 12, //make actions column wider
            },
        },
        muiTableHeadCellProps: {
            sx: {
                borderRight: '1px solid #e0e0e0', //add a border between columns
                backgroundColor: '#455a64',
                color: 'white',
                fontWeight: '500'
            },
        },
        muiTableBodyProps: {
            sx: {
                //stripe the rows, make odd rows a darker color
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td': {
                    backgroundColor: 'grey.100',
                },
            },
        },
        muiTableBodyCellProps: {
            sx: {
                borderRight: '1px solid #eeeeee', //add a border between columns
            },
        },
    });


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"th"}>
            <Box p={2}>
                <Typography variant="h5" pb={2}>{'รายการอนุมัติสินเชื่อ'}</Typography>
                <Stack gap={2} sx={{ maxWidth: '90vw' }}>
                    <Stack direction={{ lg: 'row' }} gap={2} justifyContent='space-between' alignItems='center'>
                        <TextField
                            fullWidth
                            sx={{ backgroundColor: 'white', flex: 2 }}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            variant="outlined"
                            placeholder="ค้นหา หมายเลขเคส, ชื่อ-นามสกุล, เลขบัตร, พาสปอร์ต, เบอร์โทร, IMEI, Serial Number"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {/* <Autocomplete
                            sx={{ width: { lg: '40%', xs: '100%' } }}
                            multiple
                            id='selected-registration-status'
                            value={filterStatus}
                            onChange={(event, newValue) => {
                                setFilterStatus(newValue);
                            }}
                            options={registrationStatusOptions}
                            getOptionLabel={(o) => o.display}
                            renderInput={(params) => (
                                <TextField {...params} label='สถานะการสมัคร' placeholder='' />
                            )}
                        /> */}
                    </Stack>
                    <Stack direction={'row'} gap={2}>
                    </Stack>
                    <Box sx={{ maxWidth: '100%' }}>
                        <MaterialReactTable table={waitingApprovalTable} />
                    </Box>
                    <Typography variant="h5" pt={4} pb={2}>{'ประวัติการอนุมัติ'}</Typography>
                    <Box sx={{ maxWidth: '100%' }}>
                        <MaterialReactTable table={table} />
                    </Box>
                </Stack>
            </Box>
        </LocalizationProvider>
    );
}
